import { useState, useEffect } from 'react';

const useScreenSize = () => {
  const [screenSize, setScreenSize] = useState({
    width: typeof window !== 'undefined' && window.innerWidth,
  });
  const screenResize = () => {
    if (typeof window !== 'undefined') {
      setScreenSize({
        width: window.innerWidth,
      });
    }
  };
  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', screenResize);
      return () => {
        window.removeEventListener('resize', screenResize);
      };
    }
  }, []);
  return screenSize;
};

export default useScreenSize;
