import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import LitImage from './images/LitImage';

const TrustPilot = ({ light, footer, totalReviews, showRating }) => {
  const reviewData = reviewQuery();
  const reviewImages = reviewData.allTrustpilotInfo.nodes[0];

  if (!reviewImages) {
    return null;
  }
  return (
    <div>
      <div className="trustpilot-review-container">
        <div className="trustpilot-logo-score-container">
          <div className="trustpilot-logo">
            <a
              href="https://uk.trustpilot.com/review/litfibre.com"
              target="_blank"
              title="Lit Fibre"
              aria-label="Lit Fibre"
            >
              <LitImage
                image={
                  light ? reviewImages.logoLightImage : reviewImages.logoImage
                }
                alt="lit logo"
              />
            </a>
          </div>
          <div className="trustpilot-score">
            <p>
              Excellent
              {/* {footer && 'TrustScore'} {reviewImages.score} out of 5 */}
            </p>
          </div>
        </div>
        <div className="trustpilot-stars">
          <LitImage
            image={reviewImages.starsImage}
            alt="Trustpilot review stars"
          />
        </div>
      </div>
      {showRating && (
        <>
          {' '}
          <p className="customer-review-showing">Showing our 5-star reviews</p>
          <div className="customer-review-total-container">
            <p>
              Rated <span className="bold">Excellent</span>
            </p>
            <span>|</span>
            <a
              href="https://uk.trustpilot.com/review/litfibre.com"
              target="_blank"
              aria-label={`${totalReviews} reviews`}
              title={`${totalReviews} reviews`}
            >
              <span className="bold">{totalReviews}</span> reviews
            </a>
          </div>
        </>
      )}
    </div>
  );
};

export default TrustPilot;

const reviewQuery = () =>
  useStaticQuery(graphql`
    {
      allTrustpilotReview {
        nodes {
          title
          text
          stars
          consumer {
            displayName
          }
          createdAt(fromNow: true)
        }
      }
      allTrustpilotInfo {
        nodes {
          score
          stars
          fiveStarsImage {
            childImageSharp {
              gatsbyImageData(
                quality: 75
                placeholder: BLURRED
                layout: CONSTRAINED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
          starsImage {
            childImageSharp {
              gatsbyImageData(
                quality: 75
                placeholder: BLURRED
                layout: CONSTRAINED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
          logoImage {
            childImageSharp {
              gatsbyImageData(
                quality: 75
                placeholder: BLURRED
                layout: CONSTRAINED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
          logoLightImage {
            childImageSharp {
              gatsbyImageData(
                quality: 75
                placeholder: BLURRED
                layout: CONSTRAINED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
    }
  `);
