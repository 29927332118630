import React from 'react';
import LitStructuredText from './generic/LitStructuredText';
import { graphql } from 'gatsby';

const ModularParagraph = ({ data }) => {
  /* PurgeCSS include: Yellow, White, Black */
  return data.paragraphContent?.map(
    ({ text, secondaryText, issecondarytext, backgroundColor }, index) => {
      return (
        <div
          className={`modular-paragraph-container ${backgroundColor} modular-text-center modular-padding-small`}
          key={index}
        >
          <div className="modular-p">
            {text && <LitStructuredText data={text} basic />}
            {issecondarytext && (
              <LitStructuredText data={secondaryText} basic />
            )}
          </div>
        </div>
      );
    },
  );
};
export default ModularParagraph;
export const fragment = graphql`
  fragment ModularParagraph on DatoCmsParagraph {
    internal {
      type
    }
    paragraphContent {
      backgroundColor
      issecondarytext
      text {
        value
        links {
          __typename
          ... on DatoCmsBlogArticleModel {
            id: originalId
            slug
          }
          ... on DatoCmsNewsArticleModel {
            id: originalId
            slug
          }
          ... on DatoCmsModularPage {
            id: originalId
            slug
          }
          ... on DatoCmsSimpleWebsitePageModel {
            id: originalId
            slug
          }
        }
      }
      secondaryText {
        value
        links {
          __typename
          ... on DatoCmsBlogArticleModel {
            id: originalId
            slug
          }
          ... on DatoCmsNewsArticleModel {
            id: originalId
            slug
          }
          ... on DatoCmsModularPage {
            id: originalId
            slug
          }
          ... on DatoCmsSimpleWebsitePageModel {
            id: originalId
            slug
          }
        }
      }
    }
  }
`;
