import React, { Component, useEffect } from 'react';
import Script, { ToggleElement } from './Script';
import { withPrefix } from 'gatsby';

const elementsToRemove = [''];

const LiveChat = ({ locations, enabled }) => {
  if (typeof window === 'undefined' || !window.document || !enabled) {
    return null;
  }

  ToggleElement('.embeddedServiceHelpButton', enabled);

  const scriptSrc =
    process.env.NODE_ENV === 'development' ||
    process.env.GATSBY_ENVIRONMENT === 'development'
      ? 'sf.livechat-dev.js'
      : 'sf.livechat.js';

  return (
    <>
      {/* <Script
        async
        //source="https://service.force.com/embeddedservice/5.0/esw.min.js"
        source="/scripts/esw.min.js"
        appendTo={document.body}
        elements={elementsToRemove}
      /> */}
      <Script source={withPrefix(scriptSrc)} appendTo={document.body} />
    </>
  );
};

export default LiveChat;
