import React, { useRef, useState, useContext, useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import NewsletterForm from './NewsletterForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import ActivityContext from '../context/ActivityContext';
import { useLocation } from '@gatsbyjs/reach-router';
import { NewsletterFormCookie } from '../data/Cookies';
import { Cookies } from 'react-cookie-consent';
import SEO from './SEO';
import GetSEOData from '../data/SEO';
import PropTypes from 'prop-types';
const NewsletterPopUp = () => {
  const location = useLocation();
  const { userIdle, setUserIdle } = useContext(ActivityContext);
  const [submitted, setSubmitted] = useState();
  const [defaultFormData, setDefaultFormData] = useState({});
  let formCookie = Cookies.get(NewsletterFormCookie);
  let { title, description } = GetSEOData('index');
  useEffect(() => {
    if (formCookie) {
      setDefaultFormData(JSON.parse(formCookie));
    }
  }, []);
  useEffect(() => {
    setUserIdle(false);
  }, [location]);

  const data = query();

  const legalTermsContent = [
    { terms: data.allDatoCmsSimpleWebsitePageModel.nodes[0] },
  ];

  return (
    <div
      className={`${
        userIdle
          ? 'newsletter-popup-container-open'
          : 'newsletter-popup-container-closed'
      } ${defaultFormData?.Email && 'newsletter-popup-submitted'}`}
    >
      <SEO title={title} description={description} />
      {!userIdle && (
        <button
          onClick={() => setUserIdle(true)}
          className="newsletter-chevron chevron-up"
        >
          <FontAwesomeIcon icon={faChevronUp} />
        </button>
      )}
      <NewsletterForm
        legalTermsContent={legalTermsContent}
        submitted={submitted}
        setSubmitted={setSubmitted}
        isPopUp={true}
        isProductPagePath={true}
      />
      <button
        onClick={() => {
          setUserIdle(false);
        }}
        className="newsletter-chevron"
      >
        <FontAwesomeIcon icon={faChevronDown} />
      </button>
    </div>
  );
};

export default NewsletterPopUp;

const query = () =>
  useStaticQuery(graphql`
    {
      allDatoCmsSimpleWebsitePageModel(
        filter: { slug: { eq: "legal/website-terms" } }
      ) {
        nodes {
          simpleModularContent {
            ... on DatoCmsHero {
              internal {
                type
              }
              heroContent {
                smallHeading {
                  value
                }
                subheading {
                  value
                }
                heroImageMobile {
                  gatsbyImageData
                }
                heroImageDesktop {
                  gatsbyImageData
                }
                mode
                layout
                nobottompadding
                isPostcodeSearch
              }
            }
            ... on DatoCmsParagraph {
              id
              internal {
                type
              }
              paragraphContent {
                backgroundColor
                issecondarytext
                text {
                  value
                  links {
                    __typename
                    ... on DatoCmsBlogArticleModel {
                      id: originalId
                      slug
                    }
                    ... on DatoCmsNewsArticleModel {
                      id: originalId
                      slug
                    }
                    ... on DatoCmsModularPage {
                      id: originalId
                      slug
                    }
                    ... on DatoCmsSimpleWebsitePageModel {
                      id: originalId
                      slug
                    }
                  }
                }
                secondaryText {
                  value
                  links {
                    __typename
                    ... on DatoCmsBlogArticleModel {
                      id: originalId
                      slug
                    }
                    ... on DatoCmsNewsArticleModel {
                      id: originalId
                      slug
                    }
                    ... on DatoCmsModularPage {
                      id: originalId
                      slug
                    }
                    ... on DatoCmsSimpleWebsitePageModel {
                      id: originalId
                      slug
                    }
                  }
                }
              }
            }
            ... on DatoCmsSimpleModularContentBlock {
              id
              internal {
                type
              }
              backgroundColor
              content {
                value
                links {
                  __typename
                  ... on DatoCmsBlogArticleModel {
                    id: originalId
                    slug
                  }
                  ... on DatoCmsNewsArticleModel {
                    id: originalId
                    slug
                  }
                  ... on DatoCmsModularPage {
                    id: originalId
                    slug
                  }
                  ... on DatoCmsSimpleWebsitePageModel {
                    id: originalId
                    slug
                  }
                }
              }
            }
          }
          slug
        }
      }
    }
  `);
