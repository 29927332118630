import React, { useState, useEffect } from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import LitImage from './images/LitImage';
import TrustPilot from './TrustPilot';
import LitStructuredText from './generic/LitStructuredText';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebookF,
  faXTwitter,
  faInstagram,
  faLinkedinIn,
} from '@fortawesome/free-brands-svg-icons';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import useScreenSize from '../hooks/useScreenSize';
const Footer = () => {
  const data = query();
  const footerData = data.allDatoCmsFooter.nodes[0];
  const [footerContentData, setFooterContentData] = useState(
    footerData.footerContentMobile,
  );
  const screenSize = useScreenSize();
  useEffect(() => {
    screenSize.width >= 768
      ? setFooterContentData(footerData.footerContentDesktop)
      : setFooterContentData(footerData.footerContentMobile);
  }, [screenSize.width]);

  const [isOpen, setIsOpen] = useState(false);
  const toggle = (i) => {
    if (isOpen === i) {
      setIsOpen(!isOpen);
    } else if (!isOpen == i) {
      setIsOpen(i);
    } else {
      setIsOpen(i);
    }
  };
  const updateCookieSettings = () => {
    if (typeof window !== 'undefined') {
      window.CookieFirst?.openPanel();
    }
  };
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-header">
          <LitStructuredText data={footerData.heading} basic />
          <TrustPilot light={true} footer={true} />
        </div>
        <div className="footer-links">
          {footerContentData.map(
            ({ accordionContent, title, header }, index) => {
              const content = accordionContent.map(
                ({ linkText, path, notInternalLink }, index) => {
                  return !notInternalLink ? (
                    <Link
                      key={index}
                      className="footer-links-inner"
                      to={path}
                      aria-label={linkText}
                      title={linkText}
                    >
                      {linkText}
                    </Link>
                  ) : (
                    <a
                      href={path}
                      target="_blank"
                      className="footer-links-inner"
                      key={index}
                      aria-label={linkText}
                      title={linkText}
                    >
                      {linkText}
                    </a>
                  );
                },
              );
              return (
                <div
                  key={index}
                  className={`accordion ${
                    isOpen === index ? 'uncollapsed' : 'collapsed'
                  }`}
                >
                  <button
                    className="accordion-title"
                    onClick={() => {
                      screenSize.width < 768 && toggle(index);
                    }}
                    aria-label={header}
                    title={header}
                  >
                    <p>{header}</p>
                    <span className="accordion-arrow">
                      <FontAwesomeIcon icon={faChevronDown} />
                    </span>
                  </button>
                  {isOpen === index && (
                    <div className="accordion-panel">{content}</div>
                  )}
                  {screenSize.width >= 768 && (
                    <div className="accordion-panel">{content}</div>
                  )}
                </div>
              );
            },
          )}
        </div>
      </div>

      <div className="footer-social">
        <div className="footer-social-logo">
          <Link
            to="/"
            className="footer-social-logo-link"
            aria-label="Lit Fibre"
            title="Lit Fibre"
          >
            <LitImage image={data.logoImage} alt="LIT Logo" />
          </Link>
        </div>
        <div className="footer-social-icons-cookie-button">
          <div className="footer-social-icons">
            <a
              href="https://www.facebook.com/LitFibreBroadband"
              aria-label="facebook"
              title="facebook"
            >
              <FontAwesomeIcon icon={faFacebookF} />
            </a>
            <a
              href="https://twitter.com/litfibre"
              aria-label="twitter"
              title="twitter"
            >
              <FontAwesomeIcon icon={faXTwitter} />
            </a>
            <a
              href="https://www.instagram.com/lit_fibre/"
              aria-label="instagram"
              title="instagram"
            >
              <FontAwesomeIcon icon={faInstagram} />
            </a>
            <a
              href="https://www.linkedin.com/company/litfibre"
              aria-label="linkedin"
              title="linkedin"
            >
              <FontAwesomeIcon icon={faLinkedinIn} />
            </a>
          </div>
          <button
            onClick={updateCookieSettings}
            className="footer-cookie-button"
          >
            Update Cookie settings
          </button>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
const query = () =>
  useStaticQuery(graphql`
    {
      allDatoCmsFooter {
        nodes {
          heading {
            value
          }
          footerContentMobile {
            header
            title {
              value
            }
            accordionContent {
              linkText
              path
              notInternalLink
            }
          }
          footerContentDesktop {
            header
            title {
              value
            }
            accordionContent {
              path
              linkText
              notInternalLink
            }
          }
        }
      }
      fbIconImage: file(relativePath: { eq: "social-media/fb-icon.png" }) {
        name
        relativePath
        childImageSharp {
          gatsbyImageData(
            quality: 75
            placeholder: BLURRED
            layout: CONSTRAINED
            formats: [AUTO, WEBP, AVIF]
          )
        }
        extension
        publicURL
      }
      twitterIconImage: file(
        relativePath: { eq: "social-media/twitter-icon.png" }
      ) {
        name
        relativePath
        childImageSharp {
          gatsbyImageData(
            quality: 75
            placeholder: BLURRED
            layout: CONSTRAINED
            formats: [AUTO, WEBP, AVIF]
          )
        }
        extension
        publicURL
      }
      instaIconImage: file(
        relativePath: { eq: "social-media/insta-icon.png" }
      ) {
        name
        relativePath
        childImageSharp {
          gatsbyImageData(
            quality: 75
            placeholder: BLURRED
            layout: CONSTRAINED
            formats: [AUTO, WEBP, AVIF]
          )
        }
        extension
        publicURL
      }
      logoImage: file(relativePath: { eq: "foot_logo.png" }) {
        name
        relativePath
        childImageSharp {
          gatsbyImageData(
            quality: 75
            placeholder: BLURRED
            layout: CONSTRAINED
            formats: [AUTO, WEBP, AVIF]
          )
        }
        extension
        publicURL
      }
    }
  `);
