import React, { useState, useEffect } from 'react';
import { PublicClientApplication, InteractionType } from '@azure/msal-browser';
import { MsalProvider, MsalAuthenticationTemplate } from '@azure/msal-react';
import { isMsalRoute } from '../../data/Authentication';

const MsalAuthWrapper = ({ children, authConfig, pathname }) => {
  const [msalInstance, setMsalInstance] = useState();

  useEffect(() => {
    if (!msalInstance) {
      setMsalInstance(new PublicClientApplication(authConfig.msalConfig));
    }
  }, [msalInstance]);

  const forceLogin = isMsalRoute(pathname);
  if (!msalInstance) return children;
  return (
    <MsalProvider instance={msalInstance}>
      {forceLogin && (
        <>
          <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
            authenticationRequest={authConfig.loginRequest}
          >
            {children}
          </MsalAuthenticationTemplate>
        </>
      )}
      {!forceLogin && children}
    </MsalProvider>
  );
};

export default MsalAuthWrapper;
