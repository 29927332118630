import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import {
  faPhone,
  faSterlingSign,
  faBan,
} from '@fortawesome/free-solid-svg-icons';
const GetAllProducts = (contract) => {
  const sanitizedContract = sanitizeContractName(contract);
  const result = AllProducts.filter(
    (product) => product.contract === sanitizedContract,
  );

  const pricingData = getPricingData();
  const productNodes = pricingData?.allProduct?.nodes;

  if (productNodes) {
    result.forEach((product) => {
      updateProduct(product, productNodes);
    });
  }

  return result;
};

const GetProductByProductCode = (productCode) => {
  let product = AllProducts.find((prod) => prod.productCode == productCode);
  const pricingData = getPricingData();
  const productNodes = pricingData?.allProduct?.nodes;

  if (productNodes) {
    updateProduct(product, productNodes);
  }

  return product;
};

const getPricingData = () =>
  useStaticQuery(graphql`
    {
      allProduct {
        nodes {
          productCode
          catalogPackagePrice
          catalogInstallPrice
          regionalPromo
          userType
        }
      }
    }
  `);

const updateProduct = (product, productNodes) => {
  const productNode = productNodes.find(
    (node) =>
      node.productCode === product.productCode &&
      !node.regionalPromo &&
      !node.userType,
  );

  if (productNode) {
    product.price = productNode.catalogPackagePrice;
    product.installationFee = productNode.catalogInstallPrice;
  }
};

const AllProducts = [
  {
    name: 'Lit100',
    displayName: (
      <>
        Lit<b>100</b>
      </>
    ),
    speed: '100Mb',
    shortDescription:
      "Game and stream to your heart's content with our fast & recommended for:",
    description: (
      <>
        <b>Perfect for flats, smaller homes and light browsing.</b> A great
        value fibre package that can support the needs of smaller households who
        stream and browse the internet on a few devices.
      </>
    ),
    price: 30,
    offerPrice: 28,
    installationFee: 100,
    heroImage: 'lit100Hero',
    productImage: 'lit100Product',
    productCode: 'O-LIT-FIBRE-100',
    contract: '12Months',
    features: [
      {
        icon: faSterlingSign,
        text: (
          <>
            <b>Superfast 100Mb**</b> service
          </>
        ),
      },
      {
        icon: faBan,
        text: (
          <>
            <b>Wi-Fi 6 Certified </b>Lit Hub
          </>
        ),
      },
      {
        icon: faPhone,
        text: (
          <>
            <b>Unlimited </b>data
          </>
        ),
      },
      {
        icon: 'icon4',
        text: (
          <>
            <b>Outstanding </b>customer support
          </>
        ),
      },
    ],
  },
  {
    name: 'Lit1000',
    displayName: (
      <>
        Lit<b>1000</b>
      </>
    ),
    speed: '1Gb',
    shortDescription:
      'The ultimate connection to unleash your devices full potential, recommended for:',
    description: (
      <>
        <b>Perfect for gaming & entertainment, home workers and smart homes.</b>{' '}
        Our fastest broadband package allows you to stream, work and play across
        multiple devices simultaneously, without disruption.
      </>
    ),
    price: 65,
    offerPrice: 61,
    installationFee: 100,
    heroImage: 'lit1000Hero',
    productImage: 'lit1000Product',
    productCode: 'O-LIT-FIBRE-1000',
    contract: '12Months',
    features: [
      {
        icon: faSterlingSign,
        text: (
          <>
            <b>Ultrafast 1Gb**</b> service
          </>
        ),
      },
      {
        icon: faBan,
        text: (
          <>
            <b>Wi-Fi 6 Certified </b>Lit Hub
          </>
        ),
      },
      {
        icon: faPhone,
        text: (
          <>
            <b>Unlimited </b>data
          </>
        ),
      },
      {
        icon: 'icon4',
        text: (
          <>
            <b>Outstanding </b>customer support
          </>
        ),
      },
    ],
    disclaimer:
      'Average speeds, achievable by more than 50% of users, are 900Mb on a 1Gb connection, 500Mb on a 500Mb connection, 100Mb on a 100Mb connection. 900Mb speed is not achievable by a single device over wi-fi, for this speed a wired connection is required. Ofcom, Building a Full Fibre Future report, 2018.',
  },
  {
    name: 'Lit500',
    displayName: (
      <>
        Lit<b>500</b>
      </>
    ),
    speed: '500Mb',
    shortDescription: `Game and stream to your heart's content with our fast & recommended for:`,
    description: (
      <>
        <b>Perfect for families, house shares and multi-device streaming. </b>
        The perfect fibre package for households who like to stream and surf
        across multiple devices at the same time.
      </>
    ),
    price: 45,
    offerPrice: 42,
    installationFee: 100,
    heroImage: 'lit500Hero',
    productImage: 'lit500Product',
    productCode: 'O-LIT-FIBRE-500',
    contract: '12Months',
    features: [
      {
        icon: faSterlingSign,
        text: (
          <>
            <b>Ultrafast 500Mb**</b> service
          </>
        ),
      },
      {
        icon: faBan,
        text: (
          <>
            <b>Wi-Fi 6 Certified </b>Lit Hub
          </>
        ),
      },
      {
        icon: faPhone,
        text: (
          <>
            <b>Unlimited </b>data
          </>
        ),
      },
      {
        icon: 'icon4',
        text: (
          <>
            <b>Outstanding </b>customer support
          </>
        ),
      },
    ],
  },

  // 18 Month versions
  // JanFlashPrice field added for JanFlash sale.
  {
    name: 'Lit100',
    displayName: (
      <>
        Lit<b>100</b>
      </>
    ),
    speed: '100Mb',
    shortDescription:
      'Our superfast package for smaller households, recommended for:',
    description: (
      <>
        <b>Perfect for flats, smaller homes and light browsing.</b> A great
        value fibre package that can support the needs of smaller households who
        stream and browse the internet on a few devices.
      </>
    ),
    usualPackagePrice: 30,
    price: 30,
    offerPrice: 27,
    janFlashPrice: 18,
    installationFee: 100,
    heroImage: 'lit100Hero',
    productImage: 'lit100Product',
    productCode: 'O-LIT-FIBRE-100-18M',
    contract: '18Months',
    features: [
      {
        text: `Smaller properties with fewer connected devices`,
      },
      {
        text: `Light streaming & gaming`,
      },
      {
        text: `Everyday browsing`,
      },
    ],
  },
  {
    name: 'Lit1000',
    displayName: (
      <>
        Lit<b>1000</b>
      </>
    ),
    speed: '1Gb',
    shortDescription:
      'The ultimate connection to unleash your devices full potential, recommended for:',
    description: (
      <>
        <b>Perfect for gaming & entertainment, home workers and smart homes.</b>{' '}
        Our fastest broadband package allows you to stream, work and play across
        multiple devices simultaneously, without disruption.
      </>
    ),
    usualPackagePrice: 44,
    price: 65,
    offerPrice: 58,
    janFlashPrice: 39,
    installationFee: 100,
    heroImage: 'lit1000Hero',
    productImage: 'lit1000Product',
    productCode: 'O-LIT-FIBRE-1000-18M',
    contract: '18Months',
    features: [
      {
        text: `Large connected & smart homes`,
      },
      {
        text: `Multi-device streaming & gaming`,
      },
      {
        text: `Home working & high-speed data transfer`,
      },
    ],
    disclaimer:
      'Average speeds, achievable by more than 50% of users, are 900Mb on a 1Gb connection, 500Mb on a 500Mb connection, 100Mb on a 100Mb connection. 900Mb speed is not achievable by a single device over wi-fi, for this speed a wired connection is required. Ofcom, Building a Full Fibre Future report, 2018.',
  },
  {
    name: 'Lit500',
    displayName: (
      <>
        Lit<b>500</b>
      </>
    ),
    speed: '500Mb',
    shortDescription: `Our ultrafast package perfect for busy households recommended for:`,
    description: (
      <>
        <b>Perfect for families, house shares and multi-device streaming. </b>
        The perfect fibre package for households who like to stream and surf
        across multiple devices at the same time.
      </>
    ),
    usualPackagePrice: 38,
    price: 45,
    offerPrice: 40,
    janFlashPrice: 27,
    installationFee: 100,
    heroImage: 'lit500Hero',
    productImage: 'lit500Product',
    productCode: 'O-LIT-FIBRE-500-18M',
    contract: '18Months',
    features: [
      {
        text: `Connected homes & families`,
      },
      {
        text: `Streaming & gaming`,
      },
      {
        text: `Remote workers`,
      },
    ],
  },

  // 24 Month
  {
    name: 'Lit100',
    displayName: (
      <>
        Lit<b>100</b>
      </>
    ),
    speed: '100Mb',
    shortDescription:
      'Our superfast package for smaller households, recommended for:',
    description: (
      <>
        <b>Perfect for flats, smaller homes and light browsing.</b> A great
        value fibre package that can support the needs of smaller households who
        stream and browse the internet on a few devices.
      </>
    ),
    usualPackagePrice: 28,
    price: 30,
    offerPrice: 27,
    installationFee: 100,
    heroImage: 'lit100Hero',
    productImage: 'lit100Product',
    productCode: 'O-LIT-FIBRE-100-24M',
    contract: '24Months',
    features: [
      {
        text: `Smaller properties with less connected devices`,
      },
      {
        text: `Light streaming & gaming`,
      },
      {
        text: `Everyday browsing`,
      },
    ],
  },
  {
    name: 'Lit1000',
    displayName: (
      <>
        Lit<b>1000</b>
      </>
    ),
    speed: '1Gb',
    shortDescription:
      'The ultimate connection to unleash your devices full potential, recommended for:',
    description: (
      <>
        <b>Perfect for gaming & entertainment, home workers and smart homes.</b>{' '}
        Our fastest broadband package allows you to stream, work and play across
        multiple devices simultaneously, without disruption.
      </>
    ),
    usualPackagePrice: 42,
    price: 65,
    offerPrice: 58,
    installationFee: 100,
    heroImage: 'lit1000Hero',
    productImage: 'lit1000Product',
    productCode: 'O-LIT-FIBRE-1000-24M',
    contract: '24Months',
    features: [
      {
        text: `Large connected & smart homes`,
      },
      {
        text: `Multi-device streaming & gaming`,
      },
      {
        text: `Home working & high-speed data transfer`,
      },
    ],
    disclaimer:
      'Average speeds, achievable by more than 50% of users, are 900Mb on a 1Gb connection, 500Mb on a 500Mb connection, 100Mb on a 100Mb connection. 900Mb speed is not achievable by a single device over wi-fi, for this speed a wired connection is required. Ofcom, Building a Full Fibre Future report, 2018.',
  },
  {
    name: 'Lit500',
    displayName: (
      <>
        Lit<b>500</b>
      </>
    ),
    speed: '500Mb',
    shortDescription: `Our ultrafast package perfect for busy households recommended for:`,
    description: (
      <>
        <b>Perfect for families, house shares and multi-device streaming. </b>
        The perfect fibre package for households who like to stream and surf
        across multiple devices at the same time.
      </>
    ),
    usualPackagePrice: 36,
    price: 45,
    offerPrice: 40,
    installationFee: 100,
    heroImage: 'lit500Hero',
    productImage: 'lit500Product',
    productCode: 'O-LIT-FIBRE-500-24M',
    contract: '24Months',
    features: [
      {
        text: `Connected homes & families`,
      },
      {
        text: `Streaming & gaming`,
      },
      {
        text: `Remote workers`,
      },
    ],
  },
];

const validContracts = [
  {
    id: '24Months',
    length: 24,
    title: '24 Months',
  },
  {
    id: '18Months',
    length: 18,
    title: '18 Months',
  },
  {
    id: '12Months',
    length: 12,
    title: '12 Months',
  },
];

const sanitizeContractName = (value) =>
  value && validContracts.some((contract) => contract.id === value)
    ? value
    : validContracts[0].id;

const getContractDetails = (contract) => {
  const sanitizedContract = sanitizeContractName(contract);
  return validContracts.find((contract) => contract.id === sanitizedContract);
};
const getRoundedNumber = (number) => {
  return number % 1 != 0 ? number.toFixed(2) : number;
};
export default GetAllProducts;
export {
  GetProductByProductCode,
  sanitizeContractName,
  getContractDetails,
  getRoundedNumber,
};
