import { Cookies, getCookieConsentValue } from 'react-cookie-consent';
import getCookieConsent from './CookieConsent';
export const SendFacebookConversionsEvent = async ({
  emails,
  phones,
  city,
  firstName,
  lastName,
  subscriptionId,
  county,
  dob,
  postcode,
  productId,
  quantity,
  value,
  contentCategory,
  contentName,
  contentIds,
  status,
  orderId,
  numItems,
  searchString,
  eventName,
  eventId,
}) => {
  const facebook_access_token = `${process.env.GATSBY_FACEBOOK_ACCESS_TOKEN}`;
  const facebook_pixel_id = `${process.env.GATSBY_FACEBOOK_PIXEL_ID}`;
  const affiliateTracking = getCookieConsent();
  async function getIP() {
    return fetch('https://api.ipify.org/?format=json')
      .then((response) => response.json())
      .catch((error) => {
        console.log(error.toString());
      });
  }

  const sha256 = async (data) => {
    const textAsBuffer = new TextEncoder().encode(data);
    const hashBuffer = await window.crypto.subtle.digest(
      'SHA-256',
      textAsBuffer,
    );
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const digest = hashArray
      .map((b) => b.toString(16).padStart(2, '0'))
      .join('');
    return digest;
  };

  if (
    !affiliateTracking?.performance ||
    !process.env.GATSBY_FACEBOOK_ACCESS_TOKEN ||
    (process.env.GATSBY_ACTIVE_ENV === 'development' &&
      !process.env.GATSBY_FACEBOOK_TEST_EVENT_CODE)
  ) {
    return null;
  } else {
    const current_timestamp = Math.floor(new Date() / 1000);

    const fbp = Cookies.get('_fbp');
    const fbc = Cookies.get('_fbc');

    let externalId = Cookies.get('externalId');
    if (!externalId && typeof self !== 'undefined') {
      externalId = self.crypto.randomUUID();
      Cookies.set('externalId', externalId);
    }

    let ipAddress = Cookies.get('userIP');
    if (!ipAddress && typeof self !== 'undefined') {
      ipAddress = await getIP().then((data) => {
        return data?.ip ?? '';
      });
      Cookies.set('userIP', ipAddress);
    }

    const userData = {
      em: emails ? await sha256(emails) : '',
      ph: phones ? await sha256(phones) : '',
      fn: firstName ? await sha256(firstName) : '',
      ln: lastName ? await sha256(lastName) : '',
      db: dob ? await sha256(dob) : '',
      ct: city ? await sha256(city) : '',
      st: county ? await sha256(county) : '',
      zp: postcode ? await sha256(postcode) : '',
      country: await sha256('gb'),
      external_id: externalId ? await sha256(externalId) : '',
      client_user_agent: navigator.userAgent,
      client_ip_address: ipAddress,
      fbc: fbc,
      fbp: fbp,
      subscription_id: subscriptionId,
    };

    const contents = {
      id: productId ?? 'NONE',
      quantity: quantity ?? 0,
      delivery_category: 'home_delivery',
    };

    const customData = {
      contents: [contents],
      currency: 'GBP',
      value: value,
      content_type: 'product',
      content_category: contentCategory,
      num_items: numItems,
      order_id: orderId,
      content_name: contentName,
      content_ids: contentIds,
      status: status,
      search_string: searchString,
    };

    const serverEvent = {
      event_name: eventName,
      event_time: current_timestamp,
      event_id: eventId,
      event_source_url: location.href,
      action_source: 'website',
      user_data: userData,
      custom_data: customData,
      data_processing_options: ['LDU'],
    };

    const eventsData = [serverEvent];

    let requestBody = '';
    if (process.env.GATSBY_FACEBOOK_TEST_EVENT_CODE) {
      requestBody = JSON.stringify({
        data: eventsData,
        test_event_code: process.env.GATSBY_FACEBOOK_TEST_EVENT_CODE,
      });
    } else {
      requestBody = JSON.stringify({
        data: eventsData,
      });
    }
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: requestBody,
    };

    fetch(
      `https://graph.facebook.com/v18.0/${facebook_pixel_id}/events?access_token=${facebook_access_token}`,
      requestOptions,
    );

    return null;
  }
};
