const dataLayer = ({
  event,
  eventData,
  eccomerceData,
  postcode,
  crfs,
  contractDuration,
  totalCost,
  promotions,
  product,
  addOns,
  bookInstallation,
  orderNumber,
  shipping,
  currency,
  value,
  items,
  transactionId,
  email,
  telephone,
}) => {
  if (typeof window !== 'undefined') {
    const promotionsString = promotions?.map((item) => item.name).join(', ');
    const addOnsString = addOns?.map((item) => item.name).join(', ');
    let dataLayerObj = {
      ecommerce: {
        postcode,
        crfs,
        contract_duration: contractDuration,
        total_cost: totalCost,
        promotions: promotionsString,
        product,
        add_ons: addOnsString,
        book_installation: bookInstallation,
        order_number: orderNumber,
        shipping,
        currency,
        value,
        items,
        transaction_id: transactionId,
        email,
        telephone,
      },
    };
    const dataLayerFilteredObj = { event, eventData, ecommerce: {} };
    Object.keys(dataLayerObj.ecommerce)
      .filter((key) => dataLayerObj.ecommerce[key] !== undefined)
      .forEach(
        (key) =>
          (dataLayerFilteredObj.ecommerce[key] = dataLayerObj.ecommerce[key]),
      );
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(dataLayerFilteredObj);
  }
};

export default dataLayer;
