import { createContext, useReducer, useEffect } from 'react';
import { reducer, initialState } from './reducer';
import React from 'react';
import DeviceHelper from './DeviceHelper';

export const DeviceContext = createContext({
  state: initialState,
  dispatch: () => null,
});

export const DeviceProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <DeviceContext.Provider value={[state, dispatch]}>
      <DeviceHelper />
      {children}
    </DeviceContext.Provider>
  );
};

export default DeviceProvider;
