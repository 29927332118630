import React, { useEffect } from 'react';
import Script, { RemoveElements, ToggleElement } from './Script';

const elementsToRemove = ['grecaptcha-badge'];

const Recaptcha = ({ visibility }) => {
  if (typeof window === 'undefined' || !window.document) {
    return null;
  }

  const scriptSrc = `https://www.google.com/recaptcha/api.js?render=${process.env.GATSBY_RECAPTCHA_SECRET_KEY}`;

  return (
    <>
      <style>{`.grecaptcha-badge { visibility: ${
        visibility ? 'visible' : 'hidden'
      }; }`}</style>

      <Script
        //source="/scripts/api.js"
        source={scriptSrc}
        appendTo={document.body}
        onDismount={() => {
          RemoveElements(elementsToRemove, true);

          // Replace global recaptcha to queue tasks once it gets initialized again
          grecaptcha = {};
          grecaptcha.ready = function (cb) {
            const c = '___grecaptcha_cfg';
            window[c] = window[c] || {};
            (window[c]['fns'] = window[c]['fns'] || []).push(cb);
          };
        }}
      />
    </>
  );
};

export default Recaptcha;
