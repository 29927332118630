import React from 'react';

const ButtonSwitch = ({ values, selection, onSelect }) => {
  const handleButtonClick = (event) => {
    if (onSelect) onSelect(event.target.id);
  };

  return (
    <div
      className={`buttonSwitch ${Object.keys(values).length > 2 ? ' x3' : ''}`}
    >
      {Object.keys(values).map((key) => {
        const value = values[key];
        return (
          <button
            key={key}
            id={key}
            className={selection === key ? 'selected' : ''}
            onClick={handleButtonClick}
            title={value}
            aria-label={value}
          >
            {value}
          </button>
        );
      })}
    </div>
  );
};

export default ButtonSwitch;
