const getActiveMaintenance = () => {
  const currentDateTime = Date.now();

  return scheduledMaintenancePeriods.find(
    (period) =>
      period.startDate <= currentDateTime &&
      (!period.endDate || period.endDate >= currentDateTime) &&
      period.active,
  );
};

const scheduledMaintenancePeriods = [
  {
    startDate: new Date('2022-06-04T01:50:00Z'),
    endDate: new Date('2022-06-04T16:00:00Z'),
    reason: 'Internal website upgrades',
    active: false,
  },
];

export default getActiveMaintenance;
