import React from 'react';
import { Link } from 'gatsby';
import GetAllProducts, { getContractDetails } from '../../data/Products';
import { graphql, useStaticQuery } from 'gatsby';
import LitStructuredText from '../generic/LitStructuredText';

const ProductTerms = ({ promotion, isMainPage, contract, secondaryCaveat }) => {
  const { formatTermsSlug } = require('../../util/util');
  const contractDetails = getContractDetails(contract);
  const contractLength = contractDetails?.length ?? 12;
  const currentProducts = GetAllProducts(contract);
  const promotionQuery = query();
  const datoCmsTerms =
    promotionQuery.allDatoCmsFibrePackagePromotionModel.nodes;
  const allCurrentPromotions = [];

  currentProducts.forEach((product) => {
    const applicableProduct = promotion?.products[product.productCode];
    if (!applicableProduct) return;
    const {
      promoPeriod,
      promoExpiration,
      appliedPromotions,
      promoInstallPrice,
      promoPeriodPackagePrice,
    } = applicableProduct;

    appliedPromotions?.forEach((promoName) => {
      if (
        !allCurrentPromotions.some((promo) => promo.promoName === promoName)
      ) {
        const promotion = {
          promoName,
          promoPeriod,
          promoExpiration,
          promoInstallPrice,
          promoPeriodPackagePrice,
        };

        const terms = datoCmsTerms.find(
          (promoTerms) => promoTerms.promotionCode === promoName,
        );

        if (terms) {
          let contractTerms;
          switch (contractLength) {
            case 24:
              contractTerms = terms.simpleTerms24;
              break;
            case 18:
              contractTerms = terms.simpleTerms18;
              break;
            case 12:
            default:
              contractTerms = terms.simpleTerms12;
              break;
          }
          if (contractTerms)
            promotion.terms = <LitStructuredText data={contractTerms} basic />;
        }
        allCurrentPromotions.push(promotion);
      }
    });
  });

  const currentPromotions = allCurrentPromotions.map((promotion, index) => {
    const splitToString = (string) => {
      return string.split(/(?=[A-Z])/).join(' ');
    };
    let {
      promoExpiration,
      promoInstallPrice,
      promoPeriod,
      promoName,
      promoPeriodPackagePrice,
    } = promotion;
    if (promotion.terms) {
      return promotion.terms;
    }
    return (
      <>
        *{splitToString(promoName)} offer applies to 100Mb, 500Mb and 1Gb
        services.{' '}
        {promoPeriod > 0 &&
          promoPeriodPackagePrice <= 0 &&
          `We will not charge a recurring basic broadband fee for your
        core broadband service during the initial free ${promoPeriod} month
        period.`}
        {promoPeriod > 0 &&
          promoPeriodPackagePrice > 0 &&
          `We will charge a discounted basic broadband fee for your
        core broadband service during the initial free ${promoPeriod} month
        period.`}{' '}
        After {contractLength} months, the monthly basic broadband fee will be:
        100Mb: £30, 500Mb: £45 and 1Gb: £65.{' '}
        {promoInstallPrice === 0 &&
          `Offer includes free standard installation.`}{' '}
        Offer open to new customers only and cannot be exchanged for cash and is
        non-transferable. Lit Fibre reserves the right to withdraw the Offer at
        any time. 1Gb speeds are best achieved through a wired connection. All
        prices are inclusive of VAT.{' '}
        {promoExpiration !== null
          ? `Offer ends ${promoExpiration.toLocaleString('en-GB')}.`
          : ''}
        View full terms and conditions{' '}
        <Link
          className="clickableinline"
          to={`/legal/${formatTermsSlug(promoName)}`}
          key={index}
        >
          here
        </Link>
        .
        <br />
      </>
    );
  });
  return (
    <div className="text-wrap offerTerms">
      <>
        {currentPromotions}
        **Broadband speed calculations: The UK’s average broadband speeds are
        69.4Mbps for downloads compared to Lit Fibre’s top package average speed
        of 900Mps. Average speeds taken from Ofcom’s ‘UK Home Broadband
        Performance’ report March 2023.
        {secondaryCaveat && <p>{secondaryCaveat}</p>}
      </>
    </div>
  );
};

export default ProductTerms;
const query = () =>
  useStaticQuery(graphql`
    query {
      allDatoCmsFibrePackagePromotionModel {
        nodes {
          simpleTerms18 {
            value
          }
          promotionCode
          simpleTerms12 {
            value
          }
          simpleTerms24 {
            value
          }
        }
      }
      allProduct {
        nodes {
          regionalPromo
          id
          promoPeriod
          promoInstallPrice
          promoPackagePrice
          promoExpiration
        }
      }
    }
  `);
