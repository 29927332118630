import { useContext, useEffect, useState } from 'react';
import { DeviceContext } from './DeviceProvider';

let deBounce;

const DeviceHelper = ({ widthLimit }) => {
  const [state, dispatch] = useContext(DeviceContext);
  const isBrowser = typeof window !== 'undefined';
  let isListening = false;

  if (!widthLimit) widthLimit = 767;
  useEffect(() => {
    // Updates the variable
    if (!isBrowser || isListening) return null;

    handleWindowResize();

    isListening = true;
    window.addEventListener('resize', handleWindowResize);
    if (process.env === 'development') console.log('Window Listener Mounted');

    return () => {
      if (process.env === 'development')
        console.log('Window Listener Unmounted');
      window.removeEventListener('resize', handleWindowResize);
      isListening = false;
    };
  }, [dispatch, isListening]);

  const handleWindowResize = () => {
    if (deBounce !== undefined) {
      clearTimeout(deBounce);
    }

    deBounce = setTimeout(() => {
      if (process.env === 'development') console.log('Handling Resize');

      let isMobile = window.navigator.userAgentData?.mobile;
      dispatch({ type: 'isMobile', value: isMobile });

      let isSmall = window.innerWidth <= widthLimit ? true : false;
      dispatch({ type: 'isSmall', value: isSmall });
      deBounce = undefined;
    }, 100);
  };

  return null;
};

export default DeviceHelper;
