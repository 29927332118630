import React, { useEffect } from 'react';

var scripts = undefined;

const Script = ({
  appendTo,
  source,
  contents,
  callback,
  onDismount,
  type = 'text/javascript',
}) => {
  useEffect(() => {
    // Executed on Mount
    if (typeof window === 'undefined' || !window.document) {
      return null;
    }

    scripts = document.getElementsByTagName('script');

    // Does a quick check to ensure we've not already added the script url.
    let foundScript = false;
    for (let index = 0; index < scripts.length; index++) {
      const script = scripts[index];
      foundScript |= source
        ? script.outerHTML.includes(source)
        : script.innerHTML.includes(contents);
    }

    if (!foundScript) {
      const script = document.createElement('script');

      if (source) {
        script.async = true;
        script.src = source;
      } else if (contents) {
        script.innerHTML = contents;
      }

      if (type != '') script.type = type;
      script.onload = callback;

      if (appendTo === document) {
        // Gets the first Element which should be the html container
        var element = document.firstElementChild;
        // Prepends so that this goes before any other script. This may not work if the script is loaded/reloaded in the future
        element.prepend(script);
      } else {
        appendTo.appendChild(script);
      }
    }

    // Executed On Dismount
    return () => {
      // As this sometimes seems to retain the script on the page, we want to ensure it's removed.
      for (let index = 0; index < scripts.length; index++) {
        const script = scripts[index];
        if (script.outerHTML.includes(source)) {
          script.remove();
        }
      }

      if (onDismount) onDismount();
    };
  }, []);

  return null;
};

export default Script;

export function RemoveElements(elementsToRemove, removeParent = false) {
  if (elementsToRemove.length > 0) {
    elementsToRemove.forEach((element) => {
      let foundElements = document.getElementsByClassName(element);
      for (let index = 0; index < foundElements.length; index++) {
        if (removeParent) foundElements[index].parentElement.remove();
        else foundElements[index].remove();
      }
    });

    // Removes the parent element as this is just a container div that is added automatically.
  }
}

export function ToggleElement(element, state) {
  if (typeof window === 'undefined' || !window.document) {
    return null;
  }

  [].forEach.call(document.querySelectorAll(element), function (el) {
    el.style.visibility = state ? 'visible' : 'hidden';
  });
}
