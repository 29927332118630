import React from 'react';
import LitImage from '../images/LitImage';
import { getContractDetails } from '../../data/Products';

const ProductCard = ({
  plan,
  promotion,
  onButtonClick,
  imageData,
  isOrderPage,
  compact,
  useAnchors,
  isSmall,
  isSalesJourney,
  regionalPromo,
  chosenProduct,
  isProductClick,
  selectedAddress,
}) => {
  // Elements we want to display based on the
  let planPrice,
    planInstallFee,
    twelveMonthDiscount,
    locationSaverPromo,
    forcePromotionMonths;

  const planPriceRounded = getRoundedNumber(plan.price);
  const planInstallFeeRounded = getRoundedNumber(plan.installationFee);

  const applicablePromotion = promotion?.products[plan.productCode];

  const contractDetails = getContractDetails(plan.contract);
  const contractLength = contractDetails?.length ?? 12;

  // See if there are any months for free in the promo, if so clamp it between [0-12]
  // Then subtract from 12 to calculate how many months the customer would be paying
  const promoPeriod = Math.min(
    Math.max(applicablePromotion?.promoPeriod ?? 0, 0),
    contractLength,
  );
  const paidMonths = contractLength - promoPeriod;

  const planName = plan.name.replace(/\s+/g, '');

  const isRegionSaver =
    applicablePromotion?.appliedPromotions.includes('CENTRALSAVER') ||
    applicablePromotion?.appliedPromotions.includes('WESTSAVER') ||
    applicablePromotion?.appliedPromotions.includes('EASTSAVER');

  // We only want to make the changes if we have an applicable promotion for the Product code
  // Otherwise, we want to use the default values
  if (promotion && applicablePromotion) {
    // TODO: Turn this into a function to save duplicating code
    const promoPriceRounded = getRoundedNumber(
      applicablePromotion.promoPackagePrice,
    );

    const promoInstallFeeRounded = getRoundedNumber(
      applicablePromotion.promoInstallPrice,
    );
    twelveMonthDiscount = Math.round(
      100 - (promoPriceRounded / planPriceRounded) * 100,
    );

    if (isRegionSaver && contractLength === 24) {
      if (applicablePromotion.productCode === 'O-LIT-FIBRE-1000-24M') {
        locationSaverPromo = (
          <div className="product-card-blue-highlight">
            {applicablePromotion.appliedPromotions.includes('WESTSAVER')
              ? 'Great '
              : 'Best '}
            Deal: £{applicablePromotion.promoPeriodPackagePrice} p/m
          </div>
        );
      } else {
        locationSaverPromo = (
          <div className="product-card-blue-highlight">
            Now: £{applicablePromotion.promoPeriodPackagePrice} p/m
          </div>
        );
      }
    }

    planPrice = (
      <div className="priceValue">
        {contractLength === 12 && twelveMonthDiscount > 0 && (
          <div className="discount-percentage product-card-no-highlight">
            SAVE {twelveMonthDiscount}%
          </div>
        )}
        {locationSaverPromo && locationSaverPromo}
        <div className="pricevalueWrap">
          {planPriceRounded !== promoPriceRounded &&
            promoPeriod !== 0 &&
            !locationSaverPromo && (
              <span className="free-months-then">then </span>
            )}{' '}
          {!locationSaverPromo && (
            <p className="large-price">£{promoPriceRounded}</p>
          )}
          {planPriceRounded !== promoPriceRounded && (
            <div className={`small-price`}>was (£{planPriceRounded})</div>
          )}
          <div className="line-break"></div>
          <p className="price-number-months">
            for {forcePromotionMonths ? forcePromotionMonths : paidMonths}
            months*
          </p>
        </div>
        {/* <span>then £{planPriceRounded} a month</span> */}
      </div>
    );

    planInstallFee = (
      <>
        <div>
          <div className="pricevalueWrap">
            {applicablePromotion.promoInstallPrice > 0 && (
              <div className="small-price">£{planInstallFeeRounded}</div>
            )}
            <p className="large-price">
              {applicablePromotion.promoInstallPrice == 0
                ? 'FREE'
                : `£${promoInstallFeeRounded}`}
            </p>
          </div>
          <p>installation</p>
          <span>SAVE £{planInstallFeeRounded - promoInstallFeeRounded}</span>
        </div>
      </>
    );
  } else {
    // Setup the normal Price
    planPrice = (
      <div className="priceValue">
        {contractLength === 12 && twelveMonthDiscount > 0 && (
          <div className="discount-percentage product-card-no-highlight">
            {twelveMonthDiscount}%
          </div>
        )}
        {locationSaverPromo && locationSaverPromo}
        <div className="pricevalueWrap">
          <div
            style={{
              color: '#ffa602',
              fontWeight: 700,
              textDecoration: 'line-through',
              paddingRight: '15px',
            }}
          ></div>
          {!locationSaverPromo && (
            <p className="large-price">£{planPriceRounded}</p>
          )}
          <p className="price-number-months">
            for {forcePromotionMonths ? forcePromotionMonths : paidMonths}{' '}
            months*
          </p>
        </div>

        {/* <span>then £{planPriceRounded} a month</span> */}
      </div>
    );

    planInstallFee = (
      <>
        <div>
          <p className="large-price">£{planInstallFeeRounded}</p>
          <p>installation</p>
        </div>
      </>
    );
  }

  const monthsFree =
    applicablePromotion && applicablePromotion.promoPeriodPackagePrice <= 0
      ? applicablePromotion.promoPeriod
      : 0;
  const monthsDiscount =
    monthsFree <= 0 ? applicablePromotion?.promoPeriod ?? 0 : 0;
  const discountPercentage =
    applicablePromotion?.promoPackagePrice > 0
      ? 100 -
        (applicablePromotion.promoPeriodPackagePrice /
          applicablePromotion.promoPackagePrice) *
          100
      : 0;
  const isRegionalOrOrderPage = isOrderPage || regionalPromo;

  /* PurgeCSS include: highlighted-product, unhighlighted-product */
  const highlightedProduct = chosenProduct === plan.productCode;

  return (
    <div
      className={`${
        highlightedProduct ? 'highlighted-product' : 'unhighlighted-product'
      } ${
        plan.productCode.includes('O-LIT-FIBRE-1000')
          ? 'large-product-card'
          : 'product-card'
      }`}
      onClick={(e) => onButtonClick(e, plan.productCode)}
    >
      <div className={`card-header`}>
        {plan?.productCode === 'O-LIT-FIBRE-1000-24M' && (
          <div className="ribbon">Recommended</div>
        )}

        <div className="productName">
          {/* <div className="card-image">
            <LitImage image={imageData[plan.productImage]} alt={plan.name} />
          </div> */}
          {highlightedProduct ? (
            <div className="card-product-name">
              <div className="product-image">
                {' '}
                <LitImage
                  image={
                    plan.name === 'Lit100'
                      ? imageData.Lit100Logo
                      : plan.name === 'Lit500'
                        ? imageData.Lit500Logo
                        : imageData.Lit1000Logo
                  }
                />
              </div>
            </div>
          ) : (
            <div className="card-product-name">
              <LitImage
                image={
                  plan.name === 'Lit100'
                    ? imageData.Lit100Logo
                    : plan.name === 'Lit500'
                      ? imageData.Lit500Logo
                      : imageData.Lit1000Logo
                }
              />
            </div>
          )}
        </div>
        <div className="productDetail">
          <p className="product-detail-name">
            {/* {plan.speed} */}
            {isSmall && <span> upload & down speeds</span>}
          </p>
          {!isSmall && <p>upload & down speeds</p>}
          <p>+ unlimited data</p>
        </div>
      </div>{' '}
      <div
        className={`${
          !isSalesJourney
            ? 'card-body'
            : monthsDiscount <= 0 && discountPercentage <= 0 && monthsFree <= 0
              ? 'card-body-no-discount'
              : 'card-body'
        }`}
      >
        {plan.popular && (
          <div className="blue-tag">
            <div style={{ position: 'relative' }}>
              <LitImage image={imageData['blueTagImage']} alt="Most Popular" />
              <span>Most popular</span>
            </div>
          </div>
        )}
        {!compact && !isSalesJourney && !isRegionalOrOrderPage && (
          <>
            <p className="desc">{plan.shortDescription}</p>
          </>
        )}

        <div className={`card-text${compact ? ' compact' : ''}`}>
          {monthsDiscount > 0 &&
            !locationSaverPromo &&
            isRegionalOrOrderPage && (
              <>
                {' '}
                <div className="freeMonths product-card-no-highlight ">
                  £
                  {getRoundedNumber(
                    applicablePromotion.promoPeriodPackagePrice,
                  )}
                  p/m{' '}
                </div>
                <span className="blue-highlight-small">
                  for {monthsDiscount} months
                </span>
              </>
            )}
          {monthsFree > 0 && isRegionalOrOrderPage && (
            <div className="freeMonths product-card-blue-highlight">
              {monthsFree} months free
            </div>
          )}

          {isRegionalOrOrderPage && (
            <div className="pricingInfo">
              {planPrice}

              {/* <div className="freepriceValue">{planInstallFee}</div> */}
            </div>
          )}

          <button
            className="button"
            type="button"
            onClick={(e) => onButtonClick(e, plan.productCode)}
            id={plan.productCode}
            aria-label={
              isSmall
                ? 'Buy now'
                : useAnchors
                  ? 'Find out more'
                  : isOrderPage
                    ? `Checkout ${planName}`
                    : `Get ${planName}`
            }
            title={
              isSmall
                ? 'Buy now'
                : useAnchors
                  ? 'Find out more'
                  : isOrderPage
                    ? `Checkout ${planName}`
                    : `Get ${planName}`
            }
          >
            {selectedAddress?.orderable
              ? 'Add to basket'
              : isSmall
                ? 'Buy now'
                : useAnchors
                  ? 'Find out more'
                  : isOrderPage
                    ? `Checkout ${planName}`
                    : `Get ${planName}`}
          </button>
        </div>
      </div>
    </div>
  );
};

function getRoundedNumber(number) {
  return number % 1 != 0 ? number.toFixed(2) : number;
}

export default ProductCard;
