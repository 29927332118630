import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

const Modal = ({ children, modal, animation, switchDialog, transparent }) => {
  return (
    <dialog className="modal-container" ref={modal}>
      <div className="modal-content">
        <div
          className={`modal-inner ${animation} ${
            transparent && 'modal-inner-transparent'
          }`}
        >
          {children}
        </div>
        <button className="modal-button" onClick={switchDialog}>
          <FontAwesomeIcon icon={faXmark} />
        </button>
      </div>
    </dialog>
  );
};

export default Modal;
