import React, { useContext, useEffect, useRef, useState } from 'react';
import LitImage from './images/LitImage';
import { graphql, useStaticQuery } from 'gatsby';
import AddressContext from '../context/AddressContext';
import { useLocation } from '@gatsbyjs/reach-router';
const PostcodeSearchLoading = () => {
  const { currentAddress } = useContext(AddressContext);

  const data = imageQuery();

  return (
    /* PurgeCSS include: orderable-loading, non-orderable-loading */
    currentAddress?.uprn ? (
      <div className={`order-page-loading-container`}>
        <div className="order-page-loading-inner modular-heading-third modular-heading-secondary">
          <div className="loading-image">
            <LitImage image={data.loading} />
          </div>
          <h3>We're checking your postcode</h3>
        </div>
      </div>
    ) : null
  );
};

export default PostcodeSearchLoading;

const imageQuery = () =>
  useStaticQuery(graphql`
    {
      loading: file(relativePath: { eq: "getting-deal-icon.png" }) {
        name
        relativePath
        childImageSharp {
          gatsbyImageData(
            quality: 75
            placeholder: BLURRED
            layout: CONSTRAINED
            formats: [AUTO, WEBP, AVIF]
          )
        }
        extension
        publicURL
      }
    }
  `);
