import CookieConsent, { Cookies } from 'react-cookie-consent';

const getCookieConsent = () => {
  if (typeof window !== 'undefined') {
    return window.CookieFirst?.consent;
  } else {
    const CookieFirst = Cookies.get('cookiefirst-consent');
    if (CookieFirst) {
      return JSON.parse(CookieFirst);
    } else {
      return {
        advertising: false,
        functional: false,
        necessary: true,
        performance: false,
      };
    }
  }
};

export default getCookieConsent;
