const townAlternativeNames = [
  { townName: 'welwyn garden city', alternatives: ['welwyn'] },
  { townName: 'erdington', alternatives: ['kingstanding'] },
  { townName: 'midsomer norton', alternatives: ['paulton'] },
];

const townNamesMatch = (town1, town2) => {
  if (sanitizeTownName(town1) === sanitizeTownName(town2)) return true;

  const town1Lower = town1.toLowerCase();
  const town2Lower = town2.toLowerCase();

  for (const i in townAlternativeNames) {
    const town = townAlternativeNames[i];
    if (
      (town1Lower === town.townName &&
        town.alternatives.some((alternative) => town2Lower === alternative)) ||
      (town2Lower === town.townName &&
        town.alternatives.some((alternative) => town1Lower === alternative))
    )
      return true;
  }

  return false;
};

// Let the record show I hate this but there grecaptcha.ready() doesn't return a promise
// so a poll-loop is the only way I can figure out to wait for a result
const doRecaptchaAction = async (func, action) => {
  let result = undefined;
  let ready = false;

  waitFor(() => typeof grecaptcha !== 'undefined').then(async () => {
    grecaptcha.ready(() => {
      grecaptcha
        .execute(process.env.GATSBY_RECAPTCHA_SECRET_KEY, {
          action,
        })
        .then((token) => {
          result = func(token);
          ready = true;
        });
    });
  });

  await waitFor(() => ready);
  return result;
};

const sanitizeTownName = (town) => {
  return town.toLowerCase().replaceAll('-', ' ');
};

const waitFor = (func) => {
  const poll = (resolve) => {
    if (func()) resolve();
    else setTimeout(() => poll(resolve), 20);
  };

  return new Promise(poll);
};

const formatTermsSlug = (slug) => {
  return slug.toLowerCase().replace(/\s/g, '');
};

module.exports.formatTermsSlug = formatTermsSlug;
module.exports.doRecaptchaAction = doRecaptchaAction;
module.exports.townNamesMatch = townNamesMatch;
