export const reducer = (state, action) => {
  switch (action.type) {
    case 'isBrowser':
      return {
        ...state,
        isBrowser: typeof window !== 'undefined',
      };
    case 'isSmall':
      return {
        ...state,
        isSmall: action.value,
      };
    case 'isMobile':
      return {
        ...state,
        isSmall: action.value,
      };
    case 'isListening':
      return {
        ...state,
        isListening: action.value,
      };
    default:
      return state;
  }
};

export const initialState = {
  isListening: false,
  isMobile: false,
  isSmall: false,
  isMobileOrSmall: false,
  isBrowser: typeof window !== 'undefined',
};
