export const getProductContentIds = (productCodes) => {
  const productIds = {
    'O-LIT-FIBRE-100-18M': 'x1z26rzobv',
    'O-LIT-FIBRE-100-24M': '9pjx97suvj',
    'O-LIT-FIBRE-500-18M': 'e74qsttj55',
    'O-LIT-FIBRE-500-24M': '0vmcln1ti4',
    'O-LIT-FIBRE-1000-18M': 'w3cdlvl8cr',
    'O-LIT-FIBRE-1000-24M': 'g1x789s8ba',
  };

  const productCodeArray = productCodes
    .map((code) => productIds[code.productCode])
    .filter(Boolean);

  return productCodeArray;
};
