/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/forbid-prop-types */
import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import { navigate, Link, useStaticQuery, graphql } from 'gatsby';
import { Cookies } from 'react-cookie-consent';
import ProductCard from './products/ProductCard';
import { DeviceContext } from './contexts/device/DeviceProvider';
import { initialState } from './contexts/device/reducer';
import GetCurrentPromotion from '../data/Promotions';
import GetAllProducts, { sanitizeContractName } from '../data/Products';
import ProductTerms from './products/ProductTerms';
import getActiveMaintenance from '../data/ScheduledMaintenance';
import flagService from '../services/FlagService';
import ButtonSwitch from './generic/ButtonSwitch';
import LitImage from './images/LitImage';
import Tooltip from './Tooltip';
import ProductTile from './products/ProductTiles';
import LitStructuredText from './generic/LitStructuredText';
import FacebookPixelEvents from '../services/FacebookPixelEvents';
import { getProductContentIds } from '../data/ProductContentId';
import PostcodeSearch from './PostcodeSearch';
import { SendFacebookConversionsEvent } from '../services/FacebookConversionsApiService';
import ActivityContext from '../context/ActivityContext';
import Modal from './Modal';
const productCookie = 'litfibre-product';
const contractLengthCookie = 'litfibre-contract-length';
const regionalPromoCookie = 'litfibre-promo';

const ProductInfo = ({
  header,
  subheader,
  selectedAddress,
  setSelectedAddress,
  onChangeAddress,
  isMainPage,
  isOrderPage,
  compact,
  useAnchors,
  sliderSettings,
  isLocationPage,
  overrideContracts,
  forceContract,
  audience,
  checkoutPath = '/secure-order/new/',
  onContractNameChange,
  secondaryCaveat,
  userType,
  forceRegionalPromo,
  isSalesJourney,
  isSalesJourneyHomepage,
  promotionalImage,
  isProductClick,
  scrollToForm,
  smallText,
  setSelectedPlan,
  backgroundColor,
  bottomText,
  defaultChosenProduct,
  newsletterPopUp,
  isProductPagePath,
}) => {
  const [chosenProduct, setChosenProduct] = useState(
    defaultChosenProduct ?? 'O-LIT-FIBRE-1000-24M',
  );
  const [contractName, setContractNameState] = useState(
    sanitizeContractName(forceContract),
  );

  const [regionalPromo, setRegionalPromo] = useState(undefined);
  const [readMoreSpeeds, setReadMoreSpeeds] = useState(false);
  const [animation, setAnimation] = useState(false);
  const [dialogShow, setDialogShow] = useState(true);
  const [termsDialogShow, setTermsDialogShow] = useState(true);
  const [termsAnimation, setTermsAnimation] = useState(false);
  const { userIdle, setUserIdle } = useContext(ActivityContext);
  const [inputDisplayValue, setInputDisplayValue] = useState('');

  const modal = useRef(null);

  const switchDialog = () => {
    const dialog = modal.current;
    setDialogShow((prevState) => !prevState);
    if (dialogShow) {
      dialog.showModal();
      setAnimation('open-modal');
    } else {
      setAnimation('close-modal');
      setTimeout(() => {
        dialog.close();
      }, 200);
    }
  };
  const termsModal = useRef(null);

  const switchTermsDialog = () => {
    const termsDialog = termsModal.current;
    setTermsDialogShow((prevState) => !prevState);
    if (termsDialogShow) {
      termsDialog.showModal();
      setTermsAnimation('open-modal');
    } else {
      setTermsAnimation('close-modal');
      setTimeout(() => {
        termsDialog.close();
      }, 200);
    }
  };

  const setContractName = (newContractName) => {
    const sanitizedName = sanitizeContractName(newContractName);
    setContractNameState(sanitizedName);
    if (onContractNameChange && typeof onContractNameChange === 'function')
      onContractNameChange(sanitizedName);
  };
  useEffect(() => {
    // Executed on Mount
    let cookie = Cookies.get(productCookie);
    setChosenProduct(cookie);

    if (!forceContract) {
      cookie = Cookies.get(contractLengthCookie);
      setContractName(sanitizeContractName(cookie));
    } else {
      Cookies.set(contractLengthCookie, forceContract);
      setContractName(forceContract);
    }

    if (forceRegionalPromo) setRegionalPromo(forceRegionalPromo);
    else {
      cookie = Cookies.get(regionalPromoCookie);
      setRegionalPromo(cookie ? atob(cookie) : undefined);
    }

    // Executed On Dismount
    return () => {};
  }, [forceRegionalPromo]);

  const data = imageQuery();

  let initialSlide = 0;

  if (chosenProduct) {
    if (chosenProduct === '500') {
      initialSlide = 1;
    }
    if (chosenProduct === '1000') {
      initialSlide = 2;
    }
  }

  const planIsAvailable = (plan, supplierProducts) => {
    const { productCode } = plan;
    return supplierProducts?.some(
      ({ productName }) =>
        (productCode.startsWith(productName) &&
          productName.length === productCode.length) ||
        productCode.startsWith(`${productName}-`),
    );
  };

  let promotion = GetCurrentPromotion(regionalPromo, userType);
  let plans = GetAllProducts(contractName);
  let availablePlans = plans;
  if (selectedAddress) {
    availablePlans = plans.filter(
      (plan) =>
        planIsAvailable(plan, selectedAddress?.litFibreProducts) ||
        planIsAvailable(plan, selectedAddress?.cityFibreProducts),
    );
  }
  const [state, dispatch] = useContext(DeviceContext);

  let { isMobile, isSmall } = state ? state : initialState;
  let isMobileOrSmall = isMobile || isSmall;
  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    speed: isMobile ? 0 : 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 1,
    adaptiveHeight: true,
    ...sliderSettings,
  };
  const contracts = overrideContracts ?? {
    // '12Months': '12 months',
    '18Months': '18 months',
    '24Months': '24 months',
  };
  useEffect(() => {
    isProductClick &&
      setSelectedPlan &&
      setSelectedPlan(
        availablePlans.find((plan) => plan.productCode === chosenProduct),
      );
  }, [chosenProduct]);
  const onButtonClick = (e, productCode) => {
    e.preventDefault();

    setChosenProduct(productCode);
    Cookies.set(productCookie, productCode);
    isProductClick && scrollToForm();

    let facebookEventId = '';
    if (typeof self !== 'undefined') {
      facebookEventId = self.crypto.randomUUID();
    }
    SendFacebookConversionsEvent({
      eventName: 'AddToCart',
      eventId: facebookEventId,
      contentIds: getProductContentIds([{ productCode: productCode }]),
      contentName: 'product',
    });
    FacebookPixelEvents.track(
      'AddToCart',
      {
        content_ids: getProductContentIds([{ productCode: productCode }]),
        content_type: 'product',
      },
      facebookEventId,
    );
    if (isProductPagePath) {
      productCode.includes('O-LIT-FIBRE-1000-') && navigate('/lit-1000');
      productCode.includes('O-LIT-FIBRE-500-') && navigate('/lit-500');
      productCode.includes('O-LIT-FIBRE-100-') && navigate('/lit-100');
    }
    if (useAnchors) {
      scrollToTargetAdjusted(`anchor-${productCode}`);
    } else if (selectedAddress?.uprn && !isProductClick) {
      const activeMaintenance = getActiveMaintenance();
      if (activeMaintenance) navigate('/maintenance');
      else {
        if (flagService.ReactOrderJourney)
          navigate(checkoutPath, {
            state: {
              address: selectedAddress,
            },
          });
        else {
          let salesForceURL = `${process.env.GATSBY_SALESFORCE_ORDER_URL}?c__UPRN=${selectedAddress.uprn}&c__ProductCode=${productCode}`;
          window.location.replace(salesForceURL);
        }
      }
    } else if (!isProductClick && !isProductPagePath) {
      // Handle Cookies in here,
      navigate('/secure-order/contact-form-page', {
        state: {
          fromProductCard: true,
        },
      });
    }
  };

  const scrollToTargetAdjusted = (targetId) => {
    const element = document.getElementById(targetId);
    const headerOffset = 100;
    const elementPosition = element.getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    });
  };

  const handleContractChange = (value) => {
    const newLength = sanitizeContractName(value);
    Cookies.set(contractLengthCookie, newLength);
    setContractName(newLength);
  };
  const tooltipContent = (
    <div>
      *Average speeds, achievable within a 24 hour period 919.8mb on a 1Gb
      connection, 500Mb on a 500Mb connection, 100Mb on a 100Mb connection.
      900Mb speed is not achievable by a single device when connecting to WiFi,
      for this a wired connection is required. OfCom Home Broadband Performance
      report March 2023{' '}
      <a href="https://www.ofcom.org.uk/__data/assets/pdf_file/0032/267926/march-23-home-broadband-performance.pdf">
        Read the report
      </a>
    </div>
  );
  useEffect(() => {
    defaultChosenProduct && setChosenProduct(defaultChosenProduct);
  }, []);

  return (
    <>
      <div
        className={`bootstrap ${audience && 'productInfoGray'} ${
          backgroundColor && 'product-info-container'
        } ${isSalesJourney && 'sales-background'}`}
      >
        <section
          className="product-info-section"
          style={{ paddingTop: `${isSalesJourney && '0.5em'}` }}
        >
          <div className="product-info-section-subheader-container">
            {header?.value ? (
              <div className="text-center product-info-section-subheader">
                <LitStructuredText data={header} basic />
              </div>
            ) : (
              <h2 className="text-center product-info-section-subheader">
                {header}
              </h2>
            )}
            {subheader && (
              <div className="text-center product-info-section-subheader-secondary">
                {subheader.value ? (
                  <LitStructuredText data={subheader} basic />
                ) : (
                  <h4>{subheader}</h4>
                )}
              </div>
            )}
            {smallText && <p>{smallText}</p>}
          </div>
          {!isProductPagePath && (
            <div
              className={`button-switch-container ${
                isMainPage && 'button-switch-center'
              }`}
            >
              <ButtonSwitch
                values={contracts}
                selection={contractName}
                onSelect={handleContractChange}
              />
            </div>
          )}
          {newsletterPopUp && (
            <div className="PostcodeSearchPrimary">
              <PostcodeSearch
                placeholder="Check availability"
                selectedAddress={selectedAddress}
                setSelectedAddress={setSelectedAddress}
                inputDisplayValue={inputDisplayValue}
                setInputDisplayValue={setInputDisplayValue}
              />
            </div>
          )}
          <div className="product-card-container">
            {isMobileOrSmall ? (
              <Slider {...settings}>
                {availablePlans.map((plan, index) => (
                  <ProductTile
                    key={index}
                    plan={plan}
                    promotion={promotion}
                    chosenProduct={chosenProduct}
                    onButtonClick={onButtonClick}
                    imageData={data}
                    compact={compact}
                    useAnchors={useAnchors}
                    isOrderPage={isOrderPage}
                    isSmall={isMobileOrSmall}
                    isSalesJourney={isSalesJourney}
                    regionalPromo={regionalPromo}
                    isProductClick={isProductClick}
                    selectedAddress={selectedAddress}
                    isProductPagePath={isProductPagePath}
                  />
                ))}
              </Slider>
            ) : (
              availablePlans.map((plan, index) => (
                <ProductTile
                  key={index}
                  plan={plan}
                  promotion={promotion}
                  chosenProduct={chosenProduct}
                  onButtonClick={onButtonClick}
                  imageData={data}
                  compact={compact}
                  useAnchors={useAnchors}
                  isOrderPage={isOrderPage}
                  isSmall={isMobileOrSmall}
                  isSalesJourney={isSalesJourney}
                  regionalPromo={regionalPromo}
                  isProductClick={isProductClick}
                  selectedAddress={selectedAddress}
                  isProductPagePath={isProductPagePath}
                />
              ))
            )}
          </div>

          {/* {!isSalesJourneyHomepage && (
            <div className="bandwidth-container">
              <LitImage image={data.bandwidthBudgets} />
            </div>
          )} */}
          {bottomText && (
            <div className="bottom-text">
              <LitStructuredText data={bottomText} basic />
            </div>
          )}
          {isOrderPage &&
            selectedAddress &&
            selectedAddress.orderable &&
            !isSalesJourney &&
            !isProductClick && (
              <>
                <div className="productInfo-installation">
                  <strong>
                    If you have any questions about the installation process
                    before placing your order please review our{' '}
                    <Link
                      to="/installation"
                      title="Installation"
                      aria-label="Installation"
                    >
                      installation walkthrough here
                    </Link>
                    .
                  </strong>
                </div>
                <div className="productInfo-not-ready">
                  <Link
                    to="/get-lit/register-interest"
                    state={{ inContract: true }}
                    title="Still in contract?"
                    aria-label="Still in contract?"
                  >
                    Still in contract?
                  </Link>{' '}
                </div>
              </>
            )}
          {!isSalesJourney && !regionalPromo && !newsletterPopUp && (
            <Tooltip
              id="speedTip"
              place={'top'}
              offset={{ top: 60 }}
              className="product-card-tooltip"
              color={`${backgroundColor ? '#fff' : '#e8e8e8'}`}
              tooltipText={'+ Read more about speeds'}
              content={tooltipContent}
              delayHide={800}
            />
          )}
          {newsletterPopUp && (
            <>
              {' '}
              <button onClick={switchDialog} className="speeds-button">
                Read more about speeds
              </button>
              <Modal
                animation={animation}
                dialogShow={dialogShow}
                modal={modal}
                switchDialog={switchDialog}
              >
                {tooltipContent}
              </Modal>
            </>
          )}
          {newsletterPopUp && (
            <>
              {' '}
              <button onClick={switchTermsDialog} className="speeds-button">
                Read more about our offers and speeds
              </button>
              <Modal
                animation={termsAnimation}
                dialogShow={termsDialogShow}
                modal={termsModal}
                switchDialog={switchTermsDialog}
              >
                <ProductTerms
                  contract={contractName}
                  promotion={promotion}
                  isMainPage={isMainPage}
                  secondaryCaveat={secondaryCaveat}
                />
              </Modal>
            </>
          )}
          {!isSalesJourney && !newsletterPopUp && (
            <div
              className={`${
                bottomText ? 'read-more-speeds-margin' : 'read-more-speeds'
              }`}
            >
              <button
                className="read-more-button"
                onClick={() => setReadMoreSpeeds(!readMoreSpeeds)}
                title={
                  readMoreSpeeds
                    ? 'About our offers'
                    : '*Read more about our offers and speeds'
                }
                aria-label={
                  readMoreSpeeds
                    ? 'About our offers'
                    : '*Read more about our offers and speeds'
                }
              >
                {readMoreSpeeds
                  ? 'About our offers'
                  : '*Read more about our offers and speeds'}
              </button>
              {readMoreSpeeds && (
                <div className="row mt-5" style={{ width: '100%' }}>
                  {!isLocationPage && (
                    <ProductTerms
                      contract={contractName}
                      promotion={promotion}
                      isMainPage={isMainPage}
                      secondaryCaveat={secondaryCaveat}
                    />
                  )}
                </div>
              )}
            </div>
          )}
          {promotionalImage && (
            <div className="home-promotional-image">
              <LitImage image={promotionalImage} />
            </div>
          )}
        </section>
      </div>
    </>
  );
};

ProductInfo.propTypes = {
  header: PropTypes.string,
  subheader: PropTypes.string,
  onChangeAddress: PropTypes.func,
  selectedAddress: PropTypes.object,
  chosenProduct: PropTypes.string,
};

ProductInfo.defaultProps = {
  selectedAddress: undefined,
  chosenProduct: null,
};

export default ProductInfo;
const imageQuery = () => {
  return useStaticQuery(graphql`
    {
      lit100Product: file(
        relativePath: { eq: "home-images/productCard100.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            quality: 75
            placeholder: BLURRED
            layout: CONSTRAINED
            formats: [AUTO, WEBP, AVIF]
            width: 320
          )
        }
      }
      lit500Product: file(
        relativePath: { eq: "home-images/productCard500.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            quality: 75
            placeholder: BLURRED
            layout: CONSTRAINED
            formats: [AUTO, WEBP, AVIF]
            width: 320
          )
        }
      }
      lit1000Product: file(
        relativePath: { eq: "home-images/productCard1000.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            quality: 75
            placeholder: BLURRED
            layout: CONSTRAINED
            formats: [AUTO, WEBP, AVIF]
            width: 320
          )
        }
      }
      blueTagImage: file(relativePath: { eq: "home-images/blue-tag.png" }) {
        name
        relativePath
        childImageSharp {
          gatsbyImageData(
            quality: 75
            placeholder: BLURRED
            layout: CONSTRAINED
            formats: [AUTO, WEBP, AVIF]
          )
        }
        extension
        publicURL
      }
      bandwidthBudgets: file(
        relativePath: { eq: "home-images/bandwidthDesktop.png" }
      ) {
        name
        relativePath
        childImageSharp {
          gatsbyImageData(
            quality: 100
            placeholder: BLURRED
            layout: CONSTRAINED
            formats: [AUTO, WEBP, AVIF]
          )
        }
        extension
        publicURL
      }
      bandwidthBudgetsMobile: file(
        relativePath: { eq: "home-images/bandwidthBudgetsMobile.png" }
      ) {
        name
        relativePath
        childImageSharp {
          gatsbyImageData(
            quality: 100
            placeholder: BLURRED
            layout: CONSTRAINED
            formats: [AUTO, WEBP, AVIF]
          )
        }
        extension
        publicURL
      }
      Lit100Logo: file(
        relativePath: { eq: "home-images/Lit100LogoWhite.png" }
      ) {
        name
        relativePath
        childImageSharp {
          gatsbyImageData(
            quality: 75
            placeholder: BLURRED
            layout: CONSTRAINED
            formats: [AUTO, WEBP, AVIF]
          )
        }
        extension
        publicURL
      }
      Lit500Logo: file(
        relativePath: { eq: "home-images/Lit500LogoWhite.png" }
      ) {
        name
        relativePath
        childImageSharp {
          gatsbyImageData(
            quality: 75
            placeholder: BLURRED
            layout: CONSTRAINED
            formats: [AUTO, WEBP, AVIF]
          )
        }
        extension
        publicURL
      }
      Lit1000Logo: file(
        relativePath: { eq: "home-images/Lit1000LogoWhite.png" }
      ) {
        name
        relativePath
        childImageSharp {
          gatsbyImageData(
            quality: 75
            placeholder: BLURRED
            layout: CONSTRAINED
            formats: [AUTO, WEBP, AVIF]
          )
        }
        extension
        publicURL
      }
      #SVG Files
      lit100Hero: file(relativePath: { eq: "home-images/Lit100_home.svg" }) {
        name
        relativePath
        extension
        publicURL
      }
      lit500Hero: file(relativePath: { eq: "home-images/Lit500_home.svg" }) {
        name
        relativePath
        extension
        publicURL
      }
      lit1000Hero: file(relativePath: { eq: "home-images/Lit1000_home.svg" }) {
        name
        relativePath
        extension
        publicURL
      }
      litLogoYellow: file(relativePath: { eq: "home-images/logoYellow.svg" }) {
        name
        relativePath
        extension
        publicURL
      }
    }
  `);
};
