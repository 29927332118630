export const salesJourneyAuth = {
  route: /internal\/sales/,
  msalConfig: {
    auth: {
      clientId: process.env.GATSBY_SALES_APP_ID,
      authority: process.env.GATSBY_SALES_APP_AUTHORITY,
      redirectUri: process.env.GATSBY_SALES_APP_REDIRECT,
      navigateToLoginRequestUrl: false,
    },
    cache: {
      cacheLocation: 'sessionStorage',
      storeAuthStateInCookie: false,
    },
  },
  loginRequest: {
    scopes: [process.env.GATSBY_SALES_APP_SCOPE],
  },
};

const authConfigs = [salesJourneyAuth];

export const isMsalRoute = (route) => {
  return authConfigs.some(
    (config) => config.route.test(route) && config.msalConfig,
  );
};

export const getAuthConfigForRoute = (route) =>
  authConfigs.find((config) => config.route.test(route));

export const getAccessToken = async (
  msal,
  authConfig,
  allowRedirect = true,
) => {
  const { instance, accounts } = msal;

  const request = {
    ...authConfig.loginRequest,
    account: accounts[0],
  };

  let result;
  await instance
    .acquireTokenSilent(request)
    .then((response) => (result = response.accessToken))
    .catch(() => {
      if (allowRedirect) {
        return instance
          .acquireTokenRedirect(request)
          .then((response) => (result = response.accessToken))
          .catch((e) => console.error(e));
      }
    });

  return result;
};
