import React, { useState, useContext, useRef } from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import LitImage from './images/LitImage';
import PostcodeSearch from './PostcodeSearch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AuthenticatedTemplate } from '@azure/msal-react';
import userIcon from '../images/icon-images/user.svg';
import {
  faChevronDown,
  faChevronUp,
  faMagnifyingGlass,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';
import useScreenSize from '../hooks/useScreenSize';
import AddressContext from '../context/AddressContext';
import LitStructuredText from './generic/LitStructuredText';
const Nav = ({ hideNav, hidePostcode, showAddress }) => {
  const [inputDisplayValue, setInputDisplayValue] = useState('');
  const [navIsOpen, setNavIsOpen] = useState(false);
  const [mobileNavIsOpen, setMobileNavIsOpen] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState(undefined);
  const { currentAddress, setCurrentAddress } = useContext(AddressContext);
  const [navSubmenuHeight, setNavSubmenuHeight] = useState();
  const [navSubmenuLeft, setNavSubmenuLeft] = useState();
  const [navSubmenuHovering, setNavSubmenuHovering] = useState();
  const [navActivePage, setNavActivePage] = useState(null);
  const data = query();
  const campaignSlug = data.allDatoCmsCampaignLandingPageModel.nodes[0].slug;
  const allNavigationData =
    data.allDatoCmsNavigation.nodes[0].navigationContent;
  const orderBasketData =
    data.allDatoCmsOrderJourneyModel.nodes[0].stepConfirmPackage;
  const screenSize = useScreenSize();
  const refs = useRef([]);
  const toggle = (i) => {
    if (mobileNavIsOpen === i) {
      setMobileNavIsOpen(!mobileNavIsOpen);
    } else if (!mobileNavIsOpen == i) {
      setMobileNavIsOpen(i);
    } else {
      setMobileNavIsOpen(i);
    }
  };

  const navClosed = () => {
    setNavIsOpen(!navIsOpen);
    setMobileNavIsOpen(!mobileNavIsOpen);
  };

  const mapNavContent = (links, navColumn) => {
    return links
      .filter(({ column, showInNav }) => column === navColumn && showInNav)
      .map(
        (
          {
            text,
            path,
            isExternal,
            isHeader,
            icon,
            description,
            fontawesomeIcon,
          },
          index,
        ) => {
          return isHeader ? (
            <p className="nav-submenu-header">{text}</p>
          ) : !isExternal ? (
            <li key={index}>
              <div className="nav-submenu-links-content">
                <Link
                  className="nav-submenu-link"
                  to={
                    path === '/campaign/' ? `${path}${campaignSlug}` : `${path}`
                  }
                  onClick={() => {
                    navClosed;
                    setNavActivePage(text);
                  }}
                >
                  <div
                    onClick={navClosed}
                    className={`nav-submenu-link-heading ${
                      navActivePage === text && 'nav-submenu-active-page'
                    }`}
                  >
                    {text}
                  </div>
                  {description && screenSize?.width >= 1400 && (
                    <LitStructuredText data={description} />
                  )}
                </Link>
              </div>
            </li>
          ) : (
            <li key={index}>
              <div className="nav-submenu-links-content">
                <a href={path} target="_blank" className="nav-submenu-link">
                  <div className="nav-submenu-link-heading" onClick={navClosed}>
                    {text}{' '}
                  </div>
                  {description && screenSize?.width >= 1400 && (
                    <LitStructuredText data={description} />
                  )}
                </a>
              </div>
            </li>
          );
        },
      );
  };

  const handleNavChange = (index, currentMenu) => {
    setNavSubmenuHovering(index);
    setNavSubmenuLeft(currentMenu.offsetLeft);
    const currentNavSubmenu = refs.current[index];
    if (currentNavSubmenu) setNavSubmenuHeight(currentNavSubmenu.offsetHeight);
  };

  return (
    <header className="bootstrap">
      <nav className="navigation">
        <div className="nav-mobile-container">
          {' '}
          <div className="nav-logo-container">
            <Link
              to="/"
              onClick={() => {
                setNavIsOpen(false);
              }}
              title="Lit Fibre"
              aria-label="Lit Fibre"
            >
              <div className="nav-logo" />
            </Link>
            {!showAddress && !hideNav && (
              <div className="nav-mobile-trustpilot">
                <LitImage image={data.trustpilotStars} />
              </div>
            )}
          </div>
          {showAddress &&
            currentAddress?.singleLineAddress &&
            screenSize.width < 800 && (
              <div className="basket-address-container">
                <div className="basket-address-content">
                  <h5>Your Address</h5>
                  <h6>
                    {/* {orderState.leadName && orderState.leadName + <br />} */}
                    {currentAddress?.singleLineAddress}
                  </h6>
                </div>
                <div className="basket-address-image">
                  <LitImage
                    image={orderBasketData.addressImage}
                    alt="house icon with trees"
                  />
                </div>
              </div>
            )}
          <div className="nav-mobile-user-burger-container">
            <AuthenticatedTemplate>
              <Link
                to="/internal/sales/"
                className="userIcon"
                aria-label="User Icon"
              >
                <img src={userIcon} alt="person outline icon" />
              </Link>
            </AuthenticatedTemplate>
            {!hideNav && (
              <div
                className={`nav-burger-container ${
                  navIsOpen && 'burger-animate'
                }`}
              >
                <button
                  onClick={navClosed}
                  title="Navigation burger button"
                  aria-label="Navigation burger button"
                >
                  <span className="nav-burger"></span>
                </button>
              </div>
            )}
          </div>
        </div>
        <div
          className={`navigation-content-container ${
            !navIsOpen && 'nav-unblur-background'
          }`}
        >
          {/* PurgeCSS include: nav-open, nav-closed, rotate, reset, nav-submenu-open, nav-submenu-closed  */}
          {!hideNav && (
            <>
              {screenSize.width < 800 && (
                <div
                  className={navIsOpen && 'nav-blur-background'}
                  onClick={navClosed}
                ></div>
              )}
              <div
                className={
                  screenSize.width < 800 &&
                  (navIsOpen ? 'nav-open' : 'nav-closed')
                }
              >
                <div className={`nav-content`}>
                  {allNavigationData.map(
                    (
                      {
                        navTopLevelLinkText,
                        navTopLevelPath,
                        navTopLevelLinkIcon,
                        noSubmenu,
                        navSubmenuLinks,
                        navSubmenuIntro,
                        columnCount,
                        isButton,
                        buttonColor,
                        isImage,
                        navTopLevelImage,
                      },
                      index,
                    ) => {
                      return (
                        <ul className="nav-dropdown-section" key={index}>
                          {!noSubmenu ? (
                            <>
                              <li
                                className="nav-top-level-links"
                                onMouseLeave={() => setNavSubmenuHovering(null)}
                              >
                                <a
                                  onClick={() => {
                                    toggle(index);
                                  }}
                                  className={`${
                                    navSubmenuHovering === index
                                      ? 'nav-top-level-arrow-hover'
                                      : 'nav-top-level-arrow'
                                  }`}
                                  onMouseEnter={(event) => {
                                    handleNavChange(index, event.currentTarget);
                                    setNavSubmenuHovering(index);
                                  }}
                                >
                                  {navTopLevelLinkText}
                                  {screenSize.width > 800 && (
                                    <FontAwesomeIcon icon={faChevronUp} />
                                  )}
                                </a>
                                <div
                                  className={`nav-submenu-container  ${
                                    mobileNavIsOpen === index &&
                                    screenSize.width < 800
                                      ? 'nav-submenu-open'
                                      : 'nav-submenu-closed'
                                  }`}
                                  style={
                                    screenSize.width >= 800
                                      ? {
                                          height: navSubmenuHeight ?? 0,
                                        }
                                      : {}
                                  }
                                >
                                  <ul
                                    className="nav-submenu"
                                    ref={(element) =>
                                      (refs.current[index] = element)
                                    }
                                    onMouseLeave={() =>
                                      setNavSubmenuHovering(null)
                                    }
                                    style={
                                      screenSize.width >= 800
                                        ? {
                                            opacity:
                                              navSubmenuHovering === index
                                                ? '1'
                                                : '0',
                                            pointerEvents:
                                              navSubmenuHovering !== index &&
                                              'none',
                                            transform:
                                              navSubmenuHovering === index ||
                                              navSubmenuHovering === null
                                                ? 'none'
                                                : navSubmenuHovering > index
                                                  ? 'translateX(-24px)'
                                                  : 'translateX(24px)',
                                          }
                                        : {}
                                    }
                                  >
                                    {screenSize.width < 800 && (
                                      <a
                                        className="nav-submenu-exit"
                                        onClick={() => {
                                          toggle(index);
                                        }}
                                      >
                                        <FontAwesomeIcon icon={faXmark} />
                                      </a>
                                    )}
                                    {/* <div className="nav-submenu-intro">
                                      <LitImage
                                        image={navSubmenuIntro?.navPromoImage}
                                      />
                                      <h4>{navSubmenuIntro?.navPromoHeader}</h4>
                                      <p>{navSubmenuIntro?.navPromoBody}</p>
                                    </div> */}

                                    {[...Array(columnCount)].map((_, index) => {
                                      return (
                                        <div
                                          className="nav-submenu-links-column"
                                          style={{}}
                                        >
                                          {mapNavContent(
                                            navSubmenuLinks,
                                            index + 1,
                                          )}
                                        </div>
                                      );
                                    })}
                                  </ul>
                                </div>
                              </li>
                            </>
                          ) : (
                            <li
                              className="nav-top-level-links"
                              onMouseEnter={() => setNavSubmenuHovering(null)}
                            >
                              {/* PurgeCSS include: nav-top-level-link-button-yellow, nav-top-level-link-button-white, nav-top-level-link-button-black */}
                              {!isImage ? (
                                <Link
                                  to={navTopLevelPath}
                                  onClick={navClosed}
                                  className={`${
                                    isButton
                                      ? `nav-top-level-link-button-${buttonColor}`
                                      : 'nav-top-level-link'
                                  }`}
                                >
                                  {navTopLevelLinkIcon && (
                                    <FontAwesomeIcon
                                      icon={JSON.parse(navTopLevelLinkIcon)}
                                    />
                                  )}
                                  {navTopLevelLinkText}
                                </Link>
                              ) : (
                                <Link
                                  className="nav-top-level-image"
                                  to={navTopLevelPath}
                                >
                                  <LitImage image={navTopLevelImage} />
                                </Link>
                              )}
                            </li>
                          )}
                        </ul>
                      );
                    },
                  )}
                  {/* {!hidePostcode && screenSize.width < 800 && (
                    <div className="nav-postcode-search">
                      <PostcodeSearch
                        textButton={
                          <FontAwesomeIcon icon={faMagnifyingGlass} />
                        }
                        placeholder="Check availability"
                        selectedAddress={selectedAddress}
                        setSelectedAddress={setSelectedAddress}
                        inputDisplayValue={inputDisplayValue}
                        setInputDisplayValue={setInputDisplayValue}
                      />
                    </div>
                  )} */}
                </div>
              </div>
            </>
          )}
          {showAddress &&
            currentAddress?.singleLineAddress &&
            screenSize.width >= 800 && (
              <div className="basket-address-container">
                <div className="basket-address-content">
                  <h5>Your Address</h5>
                  <h6>
                    {/* {orderState.leadName && orderState.leadName + <br />} */}
                    {currentAddress?.singleLineAddress}
                  </h6>
                </div>
                <div className="basket-address-image">
                  <LitImage
                    image={orderBasketData.addressImage}
                    alt="house icon with trees"
                  />
                </div>
              </div>
            )}
          <div className="user-icon-desktop">
            <AuthenticatedTemplate>
              <Link
                to="/internal/sales/"
                className="userIcon"
                aria-label="User Icon"
              >
                <img src={userIcon} alt="person outline icon" />
              </Link>
            </AuthenticatedTemplate>
          </div>
        </div>
      </nav>
    </header>
  );
};
Nav.propTypes = {
  siteTitle: PropTypes.string,
};

Nav.defaultProps = {
  siteTitle: '',
};
export default Nav;
const query = () =>
  useStaticQuery(graphql`
    query {
      allDatoCmsNavigation {
        nodes {
          navigationContent {
            columnCount
            navTopLevelLinkText
            navTopLevelLinkIcon
            navTopLevelPath
            isButton
            buttonColor
            isImage
            navTopLevelImage {
              gatsbyImageData
            }
            noSubmenu
            navSubmenuIntro {
              navPromoBody
              navPromoHeader
              navPromoImage {
                gatsbyImageData
              }
            }
            navSubmenuLinks {
              isExternal
              isHeader
              icon {
                gatsbyImageData
              }
              fontawesomeIcon
              showInNav
              column
              path
              text
              description {
                value
              }
            }
          }
        }
      }
      allDatoCmsOrderJourneyModel {
        nodes {
          stepConfirmPackage {
            addressImage {
              gatsbyImageData
            }
          }
        }
      }
      allDatoCmsCampaignLandingPageModel {
        nodes {
          id
          pageTitleInternalOnly
          slug
        }
      }
      trustpilotStars: file(relativePath: { eq: "trustpilot-header.png" }) {
        childImageSharp {
          gatsbyImageData(
            quality: 75
            placeholder: BLURRED
            layout: CONSTRAINED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  `);
