import React from 'react';
import {
  isList,
  isParagraph,
  isLink,
  isSpan,
  isInlineNode,
  isItemLink,
  isBlock,
} from 'datocms-structured-text-utils';
import { renderNodeRule } from 'datocms-structured-text-generic-html-renderer';
import { Link } from 'gatsby';

const commonNodeRules = [
  renderNodeRule(isLink, ({ node, children, key }) => {
    return isExternalURL(node.url) ? (
      <a href={node.url} key={key}>
        {children}
      </a>
    ) : (
      <Link to={node.url} key={key}>
        {children}
      </Link>
    );
  }),
  renderNodeRule(isBlock, ({ node, key }) => {
    return (
      <>
        <h2 key={key}>Embedded Asset</h2>
        <pre>
          <code>{JSON.stringify(node, null, 2)}</code>
        </pre>
      </>
    );
  }),
  renderNodeRule(isItemLink, ({ node, children, key }) => {
    return (
      <Link to={`/${node.item}/`} key={key}>
        {children}
      </Link>
    );
  }),
];

export const BasicStructuredTextOptions = {
  customNodeRules: [
    ...commonNodeRules,
    renderNodeRule(isList, ({ node, children, key }) => {
      return node.style === 'bulleted' ? (
        <ul>{children}</ul>
      ) : (
        <ol>{children}</ol>
      );
    }),
    renderNodeRule(isParagraph, ({ node, children, key }) => {
      return <p>{children}</p>;
    }),
  ],
};

export const StructuredTextOptions = {
  customNodeRules: [
    ...commonNodeRules,
    renderNodeRule(isList, ({ node, children, key }) => {
      return node.style === 'bulleted' ? (
        <ul
          style={{ listStyleType: 'inherit', marginLeft: '25px', padding: '0' }}
          key={key}
        >
          {children}
        </ul>
      ) : (
        <ol
          style={{ listStyleType: 'inherit', marginLeft: '25px', padding: '0' }}
        >
          {children}
        </ol>
      );
    }),
    renderNodeRule(isParagraph, ({ node, children, key }) => {
      return <Text key={key}>{children}</Text>;
    }),
  ],
};

export const Bold = ({ children }) => (
  <span className="bold" style={{ fontWeight: 'bold' }}>
    {children}
  </span>
);

export const Italic = ({ children }) => (
  <span className="italic" style={{ fontStyle: 'italic' }}>
    {children}
  </span>
);

export const Text = ({ children, key }) => (
  <p
    className="align-center"
    style={{
      textAlign: 'justify',
      lineHeight: '24px',
      paddingBottom: '12px',
    }}
    key={key}
  >
    {children}
  </p>
);

export const CallToAction = ({ children }) => (
  <p className="email-txt">{children}</p>
);

export function isExternalURL(url) {
  try {
    if (url.startsWith('http') || url.startsWith('https')) {
      return new URL(url).origin !== location.origin;
    } else {
      return !url.startsWith('/');
    }
  } catch (error) {
    return false;
  }
}
