import React, { useState, useEffect } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import LitImage from './images/LitImage';
import PostcodeSearch from './PostcodeSearch';
import LitStructuredText from './generic/LitStructuredText';
import useScreenSize from '../hooks/useScreenSize';

const BadgeHeroSection = ({
  children,
  heading,
  subHeading,
  secondaryHeroImage,
  smallHeading,
  getLit,
  mode,
  layout,
  arrowsRight,
  arrowsLeft,
  townName,
  hidePostcodeSearch,
  heroInnerNoPadding,
  notStructuredSubheading,
  heroImageDesktop,
  heroImageMobile,
  rowReverse,
}) => {
  // Swapped to using Art direction to replace the settings added in the scss

  // If no Gatsby image data is defined, apply an image directly from URL
  const [selectedAddress, setSelectedAddress] = useState(undefined);
  const [inputDisplayValue, setInputDisplayValue] = useState('');
  const screenSize = useScreenSize();
  const imageLayout = layout;
  const isGrid = imageLayout === 'Grid';
  return (
    <>
      {' '}
      {/* PurgeCSS include: YellowMode, WhiteMode, BlackMode */}
      <div
        className={`hero-section-container ${
          mode === true ? `Black` : !mode ? 'Yellow' : mode
        }Mode`}
      >
        {isGrid ? (
          <div
            className={`${
              heroInnerNoPadding
                ? 'hero-section-inner-padding'
                : 'hero-section-inner'
            } ${rowReverse && 'hero-section-inner-reverse'}`}
          >
            {children}
            <div className="hero-section-inner-content">
              <div className="hero-headings">
                {smallHeading && (
                  <div className="small-heading">
                    {smallHeading.value ? (
                      <LitStructuredText data={smallHeading} basic />
                    ) : (
                      <p>{smallHeading}</p>
                    )}
                  </div>
                )}
                {townName && <h1>{townName}</h1>}
                {heading.value ? (
                  <LitStructuredText data={heading} basic />
                ) : (
                  <h1>{heading}</h1>
                )}
                {getLit && (
                  <div className="getLitImage">
                    <LitImage
                      image={secondaryHeroImage}
                      alt="circle with people"
                    />
                  </div>
                )}
                {subHeading?.value && (
                  <LitStructuredText data={subHeading} basic />
                )}
                {notStructuredSubheading && <h2>{subHeading}</h2>}
              </div>
              <div
                className={`PostcodeSearchHero ${
                  arrowsLeft && 'PostcodeSearchHeroFlex'
                }`}
              >
                {arrowsLeft && (
                  <div className="postcode-arrows">
                    <LitImage image={arrowsLeft} alt="arrows pointing right" />
                  </div>
                )}
                {!hidePostcodeSearch && (
                  <PostcodeSearch
                    placeholder="Check availability"
                    selectedAddress={selectedAddress}
                    setSelectedAddress={setSelectedAddress}
                    inputDisplayValue={inputDisplayValue}
                    setInputDisplayValue={setInputDisplayValue}
                  />
                )}
                {arrowsRight && (
                  <div className="postcode-arrows">
                    <LitImage image={arrowsRight} alt="arrows pointing left" />
                  </div>
                )}
              </div>
            </div>
            {imageLayout === 'Grid' && (
              <div className="secondaryHeroImage">
                <LitImage image={secondaryHeroImage} alt="circle with people" />
              </div>
            )}
          </div>
        ) : (
          <div className="hero-section-full-width">
            <LitImage
              image={
                screenSize.width >= 850 ? heroImageDesktop : heroImageMobile
              }
            />
            <div
              className={`PostcodeSearchHero ${
                arrowsLeft && 'PostcodeSearchHeroFlex'
              }`}
            >
              {arrowsLeft && (
                <div className="postcode-arrows">
                  <LitImage image={arrowsLeft} alt="arrows pointing right" />
                </div>
              )}
              {!hidePostcodeSearch && (
                <PostcodeSearch
                  placeholder="Check availability"
                  selectedAddress={selectedAddress}
                  setSelectedAddress={setSelectedAddress}
                  inputDisplayValue={inputDisplayValue}
                  setInputDisplayValue={setInputDisplayValue}
                />
              )}
              {arrowsRight && (
                <div className="postcode-arrows">
                  <LitImage image={arrowsRight} alt="arrows pointing left" />
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default BadgeHeroSection;

const imageQuery = () =>
  useStaticQuery(graphql`
    {
      janFlashMobile: file(
        relativePath: { eq: "hero-images/mobileJanFlash.png" }
      ) {
        name
        relativePath
        childImageSharp {
          gatsbyImageData(
            quality: 75
            placeholder: BLURRED
            layout: CONSTRAINED
            formats: [AUTO, WEBP, AVIF]
          )
        }
        extension
        publicURL
      }
      janFlashDesktop: file(
        relativePath: { eq: "hero-images/desktopJanFlash.png" }
      ) {
        name
        relativePath
        childImageSharp {
          gatsbyImageData(
            quality: 75
            placeholder: BLURRED
            layout: CONSTRAINED
            formats: [AUTO, WEBP, AVIF]
          )
        }
        extension
        publicURL
      }
    }
  `);
