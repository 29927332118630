import React from 'react';
import LitImage from '../images/LitImage';
import { getContractDetails, getRoundedNumber } from '../../data/Products';
import { graphql, useStaticQuery } from 'gatsby';

const ProductTile = ({
  plan,
  onButtonClick,
  imageData,
  isSalesJourney,
  chosenProduct,
  isProductClick,
  promotion,
  isProductPagePath,
}) => {
  const iconData = iconQuery();
  const highlightedProduct = chosenProduct === plan.productCode;
  const defaultPlanfeatures = [
    { icon: iconData.moneyProduct, text: `No mid-contract price hikes` },
    { icon: iconData.supportProduct, text: `No setup fee` },
    { icon: iconData.contractProduct, text: `8am-8pm support` },
  ];
  const applicablePromotion = promotion?.products[plan.productCode];
  const planPriceRounded = getRoundedNumber(plan.price);
  const contractDetails = getContractDetails(plan.contract);
  const contractLength = contractDetails?.length ?? 12;
  const showPricing = isSalesJourney || isProductClick;
  const mappedPlanFeatures = !showPricing ? plan.features : defaultPlanfeatures;
  const roundedPrice =
    applicablePromotion?.promoPackagePrice &&
    getRoundedNumber(applicablePromotion?.promoPackagePrice);
  const roundedBasePrice = plan.usualPackagePrice;
  const promoPeriod = applicablePromotion?.promoPeriod ?? 0;
  const discountPrice = roundedPrice ?? planPriceRounded;
  const monthsAfterPromoPeriod = contractLength - promoPeriod;
  const promotionRoundedPrice = applicablePromotion?.promoPeriodPackagePrice
    ? getRoundedNumber(applicablePromotion?.promoPeriodPackagePrice)
    : discountPrice;

  return (
    <div
      className={`${
        highlightedProduct ? 'highlighted-product' : 'unhighlighted-product'
      } ${
        plan.productCode.includes('O-LIT-FIBRE-1000')
          ? 'large-product-card'
          : 'product-card'
      }`}
      onClick={(e) => onButtonClick(e, plan.productCode)}
    >
      <div className={`card-header`}>
        {plan?.productCode === 'O-LIT-FIBRE-1000-24M' && (
          <div className="ribbon">Recommended</div>
        )}
        <div className="productName">
          {highlightedProduct ? (
            <div className="card-product-name">
              <div className="product-image">
                {' '}
                <LitImage
                  image={
                    plan.name === 'Lit100'
                      ? imageData.Lit100Logo
                      : plan.name === 'Lit500'
                        ? imageData.Lit500Logo
                        : imageData.Lit1000Logo
                  }
                />
              </div>
            </div>
          ) : (
            <div className="card-product-name">
              <LitImage
                image={
                  plan.name === 'Lit100'
                    ? imageData.Lit100Logo
                    : plan.name === 'Lit500'
                      ? imageData.Lit500Logo
                      : imageData.Lit1000Logo
                }
              />
            </div>
          )}
        </div>
        <div className="product-icon-text-container">
          <div className="icon">
            <LitImage
              image={
                plan.name === 'Lit100'
                  ? iconData.speedIcon100
                  : plan.name === 'Lit500'
                    ? iconData.speedIcon500
                    : iconData.speedIcon1000
              }
            />
          </div>
          <p>Upload & download speeds + unlimited data</p>
        </div>
      </div>
      <div className="card-body">
        {!showPricing ? (
          <p className="description">{plan.shortDescription}</p>
        ) : (
          <div className="price-banner">
            {promoPeriod === 0 ? (
              <>
                <div className="price">
                  {roundedBasePrice > discountPrice && (
                    <div className="discount-price">£{roundedBasePrice}</div>
                  )}
                  <span>£{discountPrice}</span> per month
                </div>
                {/* <div className="contract-length">
                  {contractLength} month contract
                </div> */}
              </>
            ) : (
              <>
                <div className="price">
                  £{promotionRoundedPrice} p/m for{' '}
                  <span className="nowrap">
                    {promoPeriod} month
                    {promoPeriod > 1 && 's'}
                  </span>
                </div>
                <div className="promo-contract-length contract-length">
                  then £{discountPrice} for {monthsAfterPromoPeriod} month
                  {monthsAfterPromoPeriod > 1 && 's'}
                </div>
              </>
            )}
          </div>
        )}
        {mappedPlanFeatures.map(({ icon, text }, index) => {
          return (
            <div className="product-icon-text-container" key={index}>
              <div
                className={`${
                  icon && index !== 0
                    ? 'icon'
                    : index === 0 && icon
                      ? 'pound-icon'
                      : 'small-icon'
                }`}
              >
                <LitImage image={icon ?? iconData.bulletPointIcon} />
              </div>
              <p>{text}</p>
            </div>
          );
        })}
      </div>
      <div className="card-button-section">
        <div className="product-icon-text-container">
          <div className="icon">
            <LitImage image={iconData.wifiIcon} />
          </div>
          <p>Includes free Lit Hub & Management App</p>
        </div>

        <button
          className="button"
          type="button"
          onClick={(e) => onButtonClick(e, plan.productCode)}
          id={plan.productCode}
          aria-label={
            showPricing
              ? 'Add to Basket'
              : isProductPagePath
                ? 'Find out more'
                : 'Check availability'
          }
          title={
            showPricing
              ? 'Add to Basket'
              : isProductPagePath
                ? 'Find out more'
                : 'Check availability'
          }
        >
          {showPricing
            ? 'Add to Basket'
            : isProductPagePath
              ? 'Find out more'
              : 'Check availability'}
        </button>
      </div>
    </div>
  );
};

export default ProductTile;

const iconQuery = () => {
  return useStaticQuery(graphql`
    {
      bulletPointIcon: file(
        relativePath: { eq: "product-images/bulletPointIcon.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            quality: 75
            placeholder: BLURRED
            layout: CONSTRAINED
            formats: [AUTO, WEBP, AVIF]
            width: 320
          )
        }
      }
      supportProduct: file(
        relativePath: { eq: "product-images/supportProduct.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            quality: 75
            placeholder: BLURRED
            layout: CONSTRAINED
            formats: [AUTO, WEBP, AVIF]
            width: 320
          )
        }
      }
      contractProduct: file(
        relativePath: { eq: "product-images/contractProduct.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            quality: 75
            placeholder: BLURRED
            layout: CONSTRAINED
            formats: [AUTO, WEBP, AVIF]
            width: 320
          )
        }
      }
      moneyProduct: file(
        relativePath: { eq: "product-images/moneyProduct.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            quality: 75
            placeholder: BLURRED
            layout: CONSTRAINED
            formats: [AUTO, WEBP, AVIF]
            width: 320
          )
        }
      }
      speedIcon1000: file(
        relativePath: { eq: "product-images/speedProduct1000.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            quality: 75
            placeholder: BLURRED
            layout: CONSTRAINED
            formats: [AUTO, WEBP, AVIF]
            width: 320
          )
        }
      }
      speedIcon500: file(
        relativePath: { eq: "product-images/speedProduct500.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            quality: 75
            placeholder: BLURRED
            layout: CONSTRAINED
            formats: [AUTO, WEBP, AVIF]
            width: 320
          )
        }
      }
      speedIcon100: file(
        relativePath: { eq: "product-images/speedProduct100.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            quality: 75
            placeholder: BLURRED
            layout: CONSTRAINED
            formats: [AUTO, WEBP, AVIF]
            width: 320
          )
        }
      }
      wifiIcon: file(relativePath: { eq: "product-images/wifiProduct.png" }) {
        childImageSharp {
          gatsbyImageData(
            quality: 75
            placeholder: BLURRED
            layout: CONSTRAINED
            formats: [AUTO, WEBP, AVIF]
            width: 320
          )
        }
      }
    }
  `);
};
