import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';

const LoadingMessage = ({ text }) => {
  const [ellipsis, setEllipsis] = useState('');

  const interval = 700;

  useEffect(() => {
    const timeout = setTimeout(handleTimeout, interval);
    return () => clearTimeout(timeout);
  });

  const handleTimeout = () => {
    let newEllipsis = ellipsis + '.';
    if (newEllipsis.length > 3) newEllipsis = '';
    setEllipsis(newEllipsis);
  };

  return text + ellipsis;
};

export default LoadingMessage;
