import React, { createContext, useContext } from 'react';

const AddressContext = createContext({
  currentAddress: null,
  setCurrentAddress: () => {},
  loadingAvailability: false,
  setLoadingAvailability: () => {},
});

export default AddressContext;
