/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import React from 'react';
import { graphql } from 'gatsby';
import SEO from '../components/SEO';
import ModularSimpleHero from '../components/ModularSimpleHero';
import ModularParagraph from '../components/ModularParagraph';

import ModularSimpleContent from '../components/ModularSimpleContent';
const SimplePagePopUp = ({ simpleModularContent, title, slug, seo }) => {
  // These can be overwritten (See The Declaration)

  const GetSimpleComponent = (type, data, slug) => {
    switch (type) {
      case 'DatoCmsHero':
        return <ModularSimpleHero data={data} />;
      case 'DatoCmsParagraph':
        return <ModularParagraph data={data} />;
      case 'DatoCmsSimpleModularContentBlock':
        return <ModularSimpleContent data={data} slug={slug} />;
    }
  };
  return (
    <div className="bootstrap">
      <div className="simple-page-container">
        <SEO title={title} metaTags={seo} />
        <div className={`${slug}-simple-page`}>
          {simpleModularContent.map((component) => {
            return GetSimpleComponent(component.internal.type, component, slug);
          })}
        </div>
      </div>
    </div>
  );
};

export default SimplePagePopUp;

export const pageQuery = graphql`
  query ($id: String!) {
    datoCmsSimpleWebsitePageModel(id: { eq: $id }) {
      title
      slug
      seo {
        description
        title
        twitterCard
        image {
          gatsbyImageData
        }
      }

      simpleModularContent {
        ...ModularSimpleHero
        ...ModularParagraph
        ...ModularSimpleContent
      }
    }
  }
`;
