import React from 'react';
import BadgeHeroSection from './HeroSectionBadge';
import { graphql } from 'gatsby';
import LitStructuredText from './generic/LitStructuredText';

const ModularSimpleHero = ({ data }) => {
  const {
    heading,
    subheading,
    smallHeading,
    heroImageDesktop,
    heroImageMobile,
    mode,
    layout,
    nobottompadding: noBottomPadding,
    isPostcodeSearch,
  } = data.heroContent[0];

  return (
    <div className={`simple-hero-section-container ${mode}`}>
      <div className="simple-hero-inner modular-padding-small modular-global-width modular-text-center">
        <LitStructuredText data={heading} basic />
      </div>
    </div>
  );
};

export default ModularSimpleHero;
export const fragment = graphql`
  fragment ModularSimpleHero on DatoCmsHero {
    internal {
      type
    }
    heroContent {
      heading {
        value
      }
      smallHeading {
        value
      }
      subheading {
        value
      }
      heroImageMobile {
        gatsbyImageData
      }
      heroImageDesktop {
        gatsbyImageData
      }
      mode
      layout
      nobottompadding
      isPostcodeSearch
    }
  }
`;
