import { useEffect } from 'react';
import { SendFacebookConversionsEvent } from '../../services/FacebookConversionsApiService';
import { useLocation } from '@reach/router';

const FacebookConversionsEvent = (props) => {
  useEffect(() => {
    SendFacebookConversionsEvent(props);
  }, [useLocation()]);

  return null;
};

export default FacebookConversionsEvent;
