import PropTypes from 'prop-types';

// One Centralized File to specify all of
function GetSEOData(page) {
  // Convert to lower case to ensure that it doesn't fail based on case
  const pageName = page.toLowerCase();
  let metaData = MetaData[pageName];

  // If there is no meta data then return a default value
  if (!metaData) {
    metaData = {
      title: 'Ultrafast Fibre Home Broadband | Feel the Power',
      description:
        "Experience the internet the way it's supposed to be with ultrafast full-fibre broadband. Enjoy eyewatering upload & download speeds of up to 1Gb.",
    };
  }

  return metaData;
}

const MetaData = {
  index: {
    title: 'Ultrafast Fibre Home Broadband | Feel the Power',
    description:
      "Experience the internet the way it's supposed to be with ultrafast full-fibre broadband. Enjoy eyewatering upload & download speeds of up to 1Gb.",
  },
  'register-interest': {
    title: 'Register for Updates | Fibre Broadband Deals & Offers',
    description:
      "Discover ultrafast speeds and unlimited data. Register to find out when we're coming to you and receive our latest fibre broadband deals and offers.",
  },
  'get-lit': {
    title: 'Find Out if you can Get Lit | Fibre Broadband Deals & Offers',
    description:
      "Feel the power with Lit Fibre. Register to find out if we're currently installing in your area and receive our latest fibre broadband deals and offers.",
  },
  'contact-us': {
    title: 'Get in Touch',
    description:
      "Whether you're unsure which speed is right for you or you have questions about your contract, our friendly customer service team will be happy to help.",
  },
  help: {
    title: 'Help | Broadband FAQs',
    description:
      'Need some help? Explore our FAQs and guides to find the answers or get in touch with our friendly customer service team for further assistance.',
  },
  'all-products': {
    title: 'Full-fibre Broadband for Everyone | All Broadband Packages',
    description:
      'With three different speeds to choose from, we have full-fibre broadband packages to suit every household.',
  },
  'why-choose-lit-fibre': {
    title: 'Affordable Fibre Broadband | Better Broadband | Loyalty Offers',
    description:
      "Ready for ultrafast speeds backed by great customer service? We're bringing better broadband to UK households with transparent packages built for modern homes.",
  },
  news: {
    title: 'Fibre Broadband News & Offers | Latest News & Information',
    description:
      'Browse the latest news and updates for Lit Fibre. Keep up to date as we bring full-fibre broadband to households across the UK.',
  },
  status: {
    title: 'Service Status',
    description:
      'Lit Fibre Service Status of our network. Any known issues will appear here.',
  },
  careers: {
    title: 'Browse our latest job vacancies | Broadband Jobs and Careers',
    description:
      'Could you see yourself in the Lit Fibre team? Browse the latest career opportunities at Lit Fibre and discover your next role.',
  },
  'your-kind-of-fast': {
    title: 'Find your kind of fast | Ultrafast full fibre broadband',
    description:
      'Find your kind of fast at Lit Fibre. Choose from three symmetrical broadband speeds to find the best full fibre internet for your home. All risk-free with our 30-day money back guarantee.',
  },
  'network-coverage': {
    title: 'What Towns Are In Our Full Fibre Broadband Network | Lit Fibre',
    description:
      "We're rolling out reliable, consistent full fibre broadband to towns across the UK. Find out if we're coming to your town soon.",
  },
  audience: {
    title: 'Ultrafast Fibre Home Broadband | Feel the Power',
    description:
      "Experience the internet the way it's supposed to be with ultrafast full-fibre broadband. Enjoy eyewatering upload & download speeds of up to 1Gb.",
  },
  campaign: {
    title: 'Ultra-fast, ultra-reliable Full-Fibre Broadband is here',
    description:
      '**Broadband speed calculations: The UK’s average broadband speeds are 69.4Mbps for downloads compared to Lit Fibre’s top package average speed of 900Mps. Average speeds taken from Ofcom’s ‘UK Home Broadband Performance’ report March 2023.',
  },
  'how-to-switch': {
    title: 'Ultrafast Fibre Home Broadband | Feel the Power',
    description:
      "Experience the internet the way it's supposed to be with ultrafast full-fibre broadband. Enjoy eyewatering upload & download speeds of up to 1Gb.",
  },
  blog: {
    title: 'Lit Fibre Blog | Full Fibre Broadband Tips & Tricks',
    description:
      'Check out our blogs for tips and tricks to help you get the most out of your broadband! Read our latest internet blogs and articles.',
  },
  'refer-a-neighbour': {
    title: 'Ultrafast Fibre Home Broadband | Feel the Power',
    description:
      "Experience the internet the way it's supposed to be with ultrafast full-fibre broadband. Enjoy eyewatering upload & download speeds of up to 1Gb.",
  },
  faq: {
    title: 'Ultrafast Fibre Home Broadband | Feel the Power',
    description:
      "Experience the internet the way it's supposed to be with ultrafast full-fibre broadband. Enjoy eyewatering upload & download speeds of up to 1Gb.",
  },
};

GetSEOData.propTypes = {
  page: PropTypes.string,
};

GetSEOData.defaultProps = {
  page: '',
};

export default GetSEOData;
