import * as Sentry from '@sentry/gatsby';

const handleBreadcrumbs = (breadcrumb, hint) =>
  breadcrumb?.data?.url?.includes('page-data/') ? null : breadcrumb;

Sentry.init({
  dsn: process.env.GATSBY_SENTRY_DSN,

  tracesSampleRate: 0.0,
  debug:
    process.env.NODE_ENV === 'development' ||
    process.env.GATSBY_SENTRY_DEBUG === 'true',
  tunnel: '/api/monitoring',
  normalizeDepth: 8,
  beforeBreadcrumb: handleBreadcrumbs,
});
