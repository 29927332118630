import React, { useContext, useEffect, useRef, useState } from 'react';
import LitImage from './images/LitImage';
import { graphql, useStaticQuery } from 'gatsby';
import AddressContext from '../context/AddressContext';
import { useLocation } from '@gatsbyjs/reach-router';
const OrderPageLoading = () => {
  const { currentAddress, setLoadingAvailability } = useContext(AddressContext);

  const data = imageQuery();
  const location = useLocation();

  const showLoadingAnimationPaths = [
    /\/get-lit\/order/,
    /\/secure-order/,
    /\/internal\/sales\/order/,
    /\/internal\/sales\/register-interest/,
  ];
  const showLoadingAnimation = showLoadingAnimationPaths.some(
    (showLoadingPath) => showLoadingPath.test(location.pathname),
  );
  useEffect(() => {
    setTimeout(() => {
      setLoadingAvailability(false);
    }, 30000);
  }, []);

  return (
    /* PurgeCSS include: orderable-loading, non-orderable-loading */
    currentAddress?.uprn ? (
      <div
        className={`order-page-loading-container ${
          showLoadingAnimation &&
          `${
            currentAddress?.orderable
              ? 'orderable-loading'
              : 'non-orderable-loading'
          }`
        }`}
      >
        <div className="order-page-loading-inner modular-heading-third modular-heading-secondary">
          <div className="loading-image">
            <LitImage image={data.loading} />
          </div>
          <h3
            className={`${
              currentAddress?.orderable && 'loading-text-postcode'
            }`}
          >
            We're checking your postcode
          </h3>
          {currentAddress?.orderable && (
            <h3 className="loading-orderable-text">
              We're finding you the best deal
            </h3>
          )}
        </div>
      </div>
    ) : null
  );
};

export default OrderPageLoading;

const imageQuery = () =>
  useStaticQuery(graphql`
    {
      loading: file(relativePath: { eq: "getting-deal-icon.png" }) {
        name
        relativePath
        childImageSharp {
          gatsbyImageData(
            quality: 75
            placeholder: BLURRED
            layout: CONSTRAINED
            formats: [AUTO, WEBP, AVIF]
          )
        }
        extension
        publicURL
      }
    }
  `);
