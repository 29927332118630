import React, { createContext } from 'react';

const OneTouchSwitchContext = createContext({
  otsData: null,
  refreshOtsData: (id) => {},
  clearOtsData: () => {},
  setOtsId: (id) => {},
  error: null,
  setError: () => {},
  retryComplete: null,
  setRetryComplete: () => {},
  waitingForResponse: null,
  setWaitingForResponse: () => {},
});

export default OneTouchSwitchContext;
