export const NewsletterFieldsLive = {
  url: 'https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8',
  oid: '00D8E000000IE5R',
  email: 'email',
  firstName: 'first_name',
  lastName: 'last_name',
  contactPreferences: '00N4L000000Pymk',
  uprnValue: '00N4L000000Q40C',
  leadType: '00NTv000002lenF',
  postcodeSearched: '00NTv000002lenJ',
  ga4SourceMedium: '00NTv000002lenG',
  marketingCheckbox: '00NTv000002lenI',
};

export const NewsletterFieldsUAT = {
  ...NewsletterFieldsLive,
  url: 'https://test.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8&orgId=00D8E000000IE5R%22',
  oid: '00D8E000000IE5R',
  email: 'email',
  firstName: 'first_name',
  lastName: 'last_name',
  contactPreferences: '00N4L000000Pymk',
  uprnValue: '00N4L000000Q40C',
  leadType: '00NUE000001MMHR',
  postcodeSearched: '00NUE000001MMHU',
  ga4SourceMedium: '00NUE000001MMHS',
  marketingCheckbox: '00NUE000001MhvN',
};
