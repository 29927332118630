import React from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LitImage from './images/LitImage';

const Tooltip = ({
  color,
  content,
  place,
  id,
  className,
  icon,
  image,
  alt,
  tooltipText,
  delayHide,
}) => {
  return (
    <div className="tooltip-container">
      {image ? (
        <div data-tooltip-id={id} className="tooltip-image">
          <LitImage image={image} alt={alt} />
        </div>
      ) : icon ? (
        <span data-tooltip-id={id}>
          <FontAwesomeIcon icon={icon} />
        </span>
      ) : (
        <span data-tooltip-id="speedTip" className="tooltip-text">
          {tooltipText}
        </span>
      )}
      <ReactTooltip
        multiline
        id={id}
        place={place}
        effect="solid"
        className={className}
        arrowColor={color}
        delayHide={delayHide}
      >
        <p>{content}</p>
      </ReactTooltip>
    </div>
  );
};
export default Tooltip;
